import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Route } from 'react-router-dom';
import AdminCompanies from 'deprecated/pages/views/Admin/companies/AdminCompanies';
import { CaseEvaluationSummary } from 'modules/pipelines/pages/caseEvaluationSummary/CaseEvaluationSummary';
import { PipelineEditor } from 'deprecated/pages/viewsNew/Admin/PipelineEditor';
import React from 'react';
import { Reporting } from 'modules/reporting/pages/reporting/Reporting';
import { StepEditor } from 'deprecated/pages/viewsNew/Admin/StepEditor';
import { VariablesEditor } from 'deprecated/variablesEditor/components/VariablesEditor/VariablesEditor';
import { AdminGlomoRequest } from 'modules/glomoRequests/pages/AdminGlomoRequest';
import { AdminGlomoRequests } from 'modules/glomoRequests/pages/AdminGlomoRequests';
import { Billing } from 'modules/billing/pages/Billing';
import { BillingDetails } from 'modules/billing/pages/BillingDetails';

export const AdminRoutes = (): React.JSX.Element => {
  const routes = useRoutes<true>();

  return (
    <>
      <Route
        exact
        key={routes.companies.url}
        path={routes.companies.url}
        render={() => <AdminCompanies />}
      />
      <Route
        key={routes.pipelineEditor.url}
        path={routes.pipelineEditor.url}
        render={() => <PipelineEditor />}
      />
      <Route
        key={routes.variables.url}
        path={routes.variables.url}
        render={() => <VariablesEditor />}
      />
      <Route exact key={routes.billing.url} path={routes.billing.url} render={() => <Billing />} />
      <Route
        exact
        key={routes.billingDetails.url}
        path={routes.billingDetails.url}
        render={() => <BillingDetails />}
      />
      <Route
        key={routes.caseEvaluationSummary.url}
        path={routes.caseEvaluationSummary.url}
        render={() => <CaseEvaluationSummary />}
      />
      <Route
        exact
        key={routes.createCaseEvaluationSummary.url}
        path={routes.createCaseEvaluationSummary.url}
        render={() => <CaseEvaluationSummary />}
      />
      <Route
        key={routes.stepEditor.url}
        path={routes.stepEditor.url}
        render={() => <StepEditor />}
      />
      <Route key={routes.reporting.url} path={routes.reporting.url} render={() => <Reporting />} />
      <Route
        exact
        key={routes.glomoRequestsList.url}
        path={routes.glomoRequestsList.url}
        render={() => <AdminGlomoRequests />}
      />
      <Route
        exact
        key={routes.glomoRequest.url}
        path={routes.glomoRequest.url}
        render={() => <AdminGlomoRequest />}
      />
    </>
  );
};
