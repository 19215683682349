import { Route } from 'types/Route';

export type AdminOnlyRoutes = {
  companies: Route;
  prices: Route;
  pipelineEditor: Route;
  variables: Route;
  billing: Route;
  billingDetails: Route;
  stepEditor: Route;
  reporting: Route;
  caseEvaluationSummary: Route;
  createCaseEvaluationSummary: Route;

  companyIntegrations?: never;
  preCheck?: never;
  offices?: never;
  integrations?: never;
  partner?: never;
  partners?: never;
};

const RAILS_HOST = ['staging', 'production'].includes(process.env.NODE_ENV)
  ? undefined
  : process.env.REACT_APP_BACKEND_APP;

const railsUrl = (path: string) => {
  if (RAILS_HOST) {
    return new URL(path, RAILS_HOST).href;
  }
  return path;
};

const PRICES_URL = railsUrl('/administration/prices');

export const ADMIN_ROUTES: AdminOnlyRoutes = {
  companies: {
    url: '/companies',
    get: () => '/companies',
  },
  prices: {
    url: PRICES_URL,
    get: () => PRICES_URL,
  },
  pipelineEditor: {
    url: '/pipeline-editor',
    get: () => '/pipeline-editor',
  },
  variables: {
    url: '/variables',
    get: () => '/variables',
  },
  billingDetails: {
    url: '/billing/:id',
    get: (id) => `/billing/${id}`,
  },
  billing: {
    url: '/billing',
    get: () => '/billing',
  },
  stepEditor: {
    url: '/step-editor/:stepId',
    get: (stepId) => `/step-editor/${stepId}`,
  },
  reporting: {
    url: '/reporting/:companyId',
    get: (companyId) => `/reporting/${companyId}`,
  },
  caseEvaluationSummary: {
    url: '/cases/:caseId/case-evaluation-summary/:summaryId',
    get: (caseId, summaryId, pipelineId) =>
      `/cases/${caseId}/case-evaluation-summary/${summaryId}?pipelineId=${pipelineId}`,
  },
  createCaseEvaluationSummary: {
    url: '/cases/:caseId/case-evaluation-summary',
    get: (caseId, pipelineId) =>
      `/cases/${caseId}/case-evaluation-summary?pipelineId=${pipelineId}`,
  },
};
