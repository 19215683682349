import React, { useEffect } from 'react';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { TeamTable } from './components/TeamTable/TeamTable';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { AddTeamMember } from 'modules/company/components/AddTeamMember/AddTeamMember';
import { useCompanyUsers } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Heading, useModalStore } from '@localyze-pluto/components';

import { trackPageView } from 'modules/analytics/analytics';
import { useCurrentUser } from 'config/CurrentUserContext';
import { PERMISSIONS_DOCUMENTATION_URL } from 'constants/permissions';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { PARTNERS_HUB } from 'modules/featureFlags/types/flags';

export const Team = (): React.JSX.Element => {
  const isPartnersHubActive = useIsFeatureFlagActive(PARTNERS_HUB);

  const { company_id, isAdmin, isHrAdmin } = useCurrentUser();
  const addMemberModalState = useModalStore();
  const { companyId: id } = useParams<{ companyId: string }>();

  const companyId = id ? Number(id) : company_id;

  const {
    data: teamMembers,
    isSuccess: isTeamSuccess,
    error: isTeamError,
    refetch: refetchTeam,
  } = useCompanyUsers({ params: { companyId } });

  const routes = useRoutes();

  useEffect(() => {
    trackPageView('Team');
  }, []);

  if (isTeamError || !companyId) {
    return <ErrorStatePage />;
  }

  if (!isTeamSuccess) {
    return <ContainedLoadingState />;
  }

  const hasEditPermissions = isAdmin || isHrAdmin;

  return (
    <PageLayout
      maxW="1200px"
      mx="auto"
      titleButton={
        isPartnersHubActive ? (
          <Button
            as={Link}
            leadingIcon="arrow-left"
            p="d0"
            to={routes.settings.get(companyId)}
            variant="ghost"
          >
            Back to Settings
          </Button>
        ) : undefined
      }
      w="100%"
    >
      <Heading as="h1" size="title-screen">
        {isAdmin ? 'Manage team' : 'Manage your team'}
      </Heading>
      <Box.div display="flex" flexDirection="column" gap="d2" marginBottom="m4" marginTop="m1">
        {hasEditPermissions && (
          <Box.div display="flex" justifyContent="space-between">
            <Button
              as="a"
              href={PERMISSIONS_DOCUMENTATION_URL}
              leadingIcon="circle-help"
              target="_blank"
              textDecoration="underline"
              variant="ghost"
            >
              Learn more about user roles
            </Button>
            <Button leadingIcon="plus" onClick={addMemberModalState.show} variant="secondary">
              Add team member
            </Button>
          </Box.div>
        )}

        <TeamTable canEdit={hasEditPermissions} companyId={companyId} teamMembers={teamMembers} />
      </Box.div>
      <AddTeamMember companyId={companyId} onSubmit={refetchTeam} state={addMemberModalState} />
    </PageLayout>
  );
};
