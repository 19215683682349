import React, { useState } from 'react';

import { Company } from 'modules/company/types/Company';
import { useCompanyFields } from 'modules/company/components/CompanyInformation/utils/useCompanyFields';
import { useUpdateCompany } from 'modules/company/api/useUpdateCompany/useUpdateCompany';
import { Box, Heading, useToast } from '@localyze-pluto/components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditButton } from 'modules/company/components/EditButton/EditButton';
import { ControlledFormField } from 'components/ControlledFormField/ControlledFormField';
import FormSection from 'modules/company/components/FormSection/FormSection';
import * as yup from 'yup';
import { useCurrentUser } from 'config/CurrentUserContext';
import { SectionCard } from 'components/SectionCard/SectionCard';

type Props = {
  readonly company: Company;
  readonly canEdit: boolean;
};

export type CompanyFormValues = {
  id: number;
  registered_name: string;
  hubspot_id: string;
  industry: string;
  employee_count: string;
  founding_year: string;
  billing_center_id: string;
  billing_street_and_number: string;
  billing_postal_code: string;
  billing_city: string;
  billing_country_id: string;
  billing_invoice_email: string;
  vat_number: string;
};

const schema = yup.object({
  id: yup.number().required(),
  registered_name: yup.string().required(),
  industry: yup.string().defined(),
  hubspot_id: yup
    .string()
    .defined()
    .matches(/[0-9]*/),
  employee_count: yup
    .string()
    .defined()
    .matches(/[0-9]*/),
  founding_year: yup
    .string()
    .defined()
    .matches(/[0-9]*/),
  billing_center_id: yup.string().required('Please select a billing center.'),
  billing_street_and_number: yup.string().required(),
  billing_postal_code: yup.string().required(),
  billing_city: yup.string().required(),
  billing_country_id: yup.string().required(),
  billing_invoice_email: yup.string().required(),
  vat_number: yup.string().required(),
});

export const ManageCompanyInformation = ({ company, canEdit }: Props): React.JSX.Element => {
  const toast = useToast();

  const { isAdmin } = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: updateCompany } = useUpdateCompany({
    onSuccess: () => {
      setIsEditing(false);
      toast('The company information was successfully updated.', 'success');
    },
    onError: (error) => {
      toast(
        error.response?.data.message ||
          'An error has occurred while updating the company information.',
        'error',
      );
    },
  });

  const onSubmit = async (values: CompanyFormValues) => {
    updateCompany(values);
  };

  const { generalFields } = useCompanyFields(isAdmin);

  const { control, handleSubmit, reset } = useForm({
    values: {
      ...company,
      hubspot_id: company.hubspot_id?.toString() || '',
      employee_count: company.employee_count?.toString() || '',
      founding_year: company.founding_year?.toString() || '',
      industry: company.industry || '',
      vat_number: company.vat_number || '',
      billing_country_id: company.billing_country_id?.toString() || '',
      billing_center_id: company.billing_center.id.toString(),
      billing_invoice_email: company.billing_invoice_email || '',
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  return (
    <SectionCard data-testid="general-company-information" marginBottom="d4">
      <SectionCard.Header py="d2">
        <Heading as="h2" fontSize="fontSize40" marginBottom="d0">
          General company information
        </Heading>
      </SectionCard.Header>
      <SectionCard.Content>
        <Box.form onSubmit={handleSubmit(onSubmit)} w="100%">
          <FormSection
            border={false}
            headerAction={
              canEdit && (
                <EditButton
                  isEditing={isEditing}
                  reset={reset}
                  setIsEditing={setIsEditing}
                  variant="ghost"
                />
              )
            }
            heading=""
          >
            {generalFields.map((field) => {
              return (
                <ControlledFormField
                  control={control}
                  field={field}
                  isEditing={isEditing}
                  key={field.id}
                />
              );
            })}
          </FormSection>
        </Box.form>
      </SectionCard.Content>
    </SectionCard>
  );
};
