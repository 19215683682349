import { Route } from 'types/Route';

export type HROnlyRoutes = {
  preCheck: Route;
  offices: Route;
  subscription: Route;
  integrations: Route;
  companyIntegrations: Route;
  reporting: Route;
  partners: Route;
  partner: Route;

  companies?: never;
  prices?: never;
  pipelineEditor?: never;
  variables?: never;
  billing?: never;
  billingDetails?: never;
  stepEditor?: never;
  caseEvaluationSummary?: never;
  createCaseEvaluationSummary?: never;
};

export const HR_ROUTES: HROnlyRoutes = {
  preCheck: {
    url: '/pre-check',
    get: () => '/pre-check',
  },
  offices: {
    url: '/offices',
    get: () => '/offices',
  },
  subscription: {
    url: '/billing',
    get: () => '/billing',
  },
  integrations: {
    url: '/integrations',
    get: () => '/integrations',
  },
  // to replace /integrations once partners_hub feature flag is removed
  companyIntegrations: {
    url: '/companies/:companyId/integrations',
    get: (companyId) => `/companies/${companyId}/integrations`,
  },
  reporting: {
    url: '/reporting',
    get: () => '/reporting',
  },
  partners: {
    url: '/companies/:companyId/partners',
    get: (companyId) => `/companies/${companyId}/partners`,
  },
  partner: {
    url: '/partners/:partnerId',
    get: (partnerId, companyId, serviceCategoryId) =>
      `/partners/${partnerId}?company_id=${companyId}&service_category_id=${serviceCategoryId}`,
    getWithSearch: (partnerId, companyId, serviceCategoryId) => ({
      pathname: `/partners/${partnerId}`,
      search: `?company_id=${companyId}&service_category_id=${serviceCategoryId}`,
    }),
  },
};
