import React, { useEffect, useState } from 'react';
import { Box, useToast } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useOffice } from 'modules/offices/api/useOffice/useOffice';
import { useUpdateOffice } from 'modules/offices/api/useUpdateOffice/useUpdateOffice';
import { useParams } from 'react-router-dom';
import { EditButton } from 'modules/company/components/EditButton/EditButton';
import { AddOfficePayload } from 'modules/offices/api/useAddOffice/useAddOffice.types';
import { OfficeForm } from 'modules/company/pages/Offices/OfficeForm';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';

export const Office = ({ officeId }: { officeId: number }): React.JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const { companyId: companyIdFromUrl } = useParams<{ companyId: string }>();
  const { isHrViewer, company_id } = useCurrentUser();

  const companyId = Number(companyIdFromUrl) || company_id;
  const { data: office, refetch } = useOffice({
    params: { companyId, officeId },
  });

  const { mutate: updateOffice } = useUpdateOffice({
    onSuccess: () => {
      toast('Successfully updated office.', 'success');
      setIsEditing(false);
    },
    onError: () => {
      toast('Error updating office.', 'error');
      setIsEditing(false);
    },
  });

  useEffect(() => {
    refetch();
  }, [officeId, refetch]);

  const onSubmit = (values: AddOfficePayload) => {
    updateOffice({
      companyId,
      officeId,
      formValues: values,
    });
  };

  const isPartnersHubActive = useIsFeatureFlagActive('partners_hub');

  return (
    <Box.section
      className={`tw-rounded-2xl tw-bg-white ${isPartnersHubActive ? 'tw-py-8 tw-px-6' : 'tw-p-8'}`}
      w="100%"
    >
      {office ? (
        <OfficeForm
          headerAction={
            isHrViewer
              ? undefined
              : (reset) => (
                  <EditButton
                    isEditing={isEditing}
                    reset={reset}
                    setIsEditing={setIsEditing}
                    variant={isPartnersHubActive ? 'ghost' : 'secondary'}
                  />
                )
          }
          isEditing={isEditing}
          office={office}
          onSubmit={onSubmit}
        />
      ) : (
        <Box.div alignItems="center" display="flex" flexDirection="column">
          <ContainedLoadingState />
        </Box.div>
      )}
    </Box.section>
  );
};
