import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  DrawerStore,
  Heading,
  RichText,
} from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { AdditionalService } from 'modules/additionalServices/types/AdditionalService';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import { usePartnersWithConfiguration } from 'modules/partners/hooks/usePartnersWithConfiguration';
import React from 'react';

export const AdditionalServiceDrawer = ({
  additionalService,
  destinationCountryId,
  companyId,
  store,
}: {
  additionalService: AdditionalService;
  destinationCountryId?: number;
  companyId?: number;
  store: DrawerStore;
}): React.JSX.Element => {
  const { partnersWithConfiguration, isSuccess } = usePartnersWithConfiguration({
    additionalServiceId: additionalService.id,
    serviceCategoryId: additionalService.service_category.id,
    destinationCountryId,
    companyId: Number(companyId),
  });

  const availablePartners = partnersWithConfiguration.filter((partner) => !partner.isDisabled);

  const partnersOffered =
    availablePartners.length > 0
      ? availablePartners
          .sort((partner) => (partner.isPreferred ? -1 : 1))
          .map((partner) => `${partner.name}${partner.isPreferred ? ' [Preferred]' : ''}`)
          .join(', ')
      : 'No partners available';

  return (
    <Drawer store={store}>
      <DrawerHeader>
        <DrawerHeading>
          {additionalService.service_category.name} - {additionalService.name}
        </DrawerHeading>
      </DrawerHeader>
      <DrawerBody>
        <SectionCard>
          <SectionCard.Header>
            <Heading as="h3" marginBottom="d0" size="heading60">
              Service information
            </Heading>
          </SectionCard.Header>
          <SectionCard.Content padding="d0">
            {isSuccess ? (
              <KeyValueList.Ul w="100%">
                <KeyValueList.Li
                  alignItems="flex-start"
                  keyName="Partners"
                  value={partnersOffered}
                />
                <KeyValueList.Li
                  alignItems="flex-start"
                  keyName="Service details"
                  value={
                    additionalService.descriptionRichText && (
                      <RichText>
                        {documentToHtmlString(additionalService.descriptionRichText)}
                      </RichText>
                    )
                  }
                />
              </KeyValueList.Ul>
            ) : (
              <Box.div p="d4">
                <ContainedLoadingState />
              </Box.div>
            )}
          </SectionCard.Content>
        </SectionCard>
      </DrawerBody>
    </Drawer>
  );
};
