import { Box, BoxProps, Button } from '@localyze-pluto/components';
import React from 'react';

const Ul = ({
  children,
  borderBottomColor = 'colorBorderWeakest',
  borderBottomWidth = 'borderBottomWidth',
  ...props
}: BoxProps & {
  children: React.ReactNode;
}): React.JSX.Element => (
  <Box.ul
    borderBottomColor={borderBottomColor}
    borderBottomWidth={borderBottomWidth}
    mb="0"
    p="0"
    {...props}
  >
    {children}
  </Box.ul>
);

const Li = ({
  keyName,
  value,
  alignItems = 'center',
  borderBottomColor = 'colorBorderWeakest',
  borderBottomStyle = 'borderStyleSolid',
  borderBottomWidth = 'borderWidth10',
  px = 'd6',
  py = 'd4',
  ...props
}: BoxProps & {
  keyName: string;
  value: React.JSX.Element | string | null | undefined;
}): React.JSX.Element => (
  <Box.li
    alignItems={alignItems}
    borderBottomColor={borderBottomColor}
    borderBottomStyle={borderBottomStyle}
    borderBottomWidth={borderBottomWidth}
    display="flex"
    fontSize="fontSize20"
    gap="d4"
    key={keyName}
    px={px}
    py={py}
    {...props}
  >
    <Box.div color="contentSecondary" w="250px">
      {keyName}
    </Box.div>
    <Box.div alignItems={alignItems} display="flex" justifyContent="space-between" w="100%">
      {React.isValidElement(value) ? value : (value ?? '-')}
    </Box.div>
  </Box.li>
);

type KeyValueListItem = {
  key: string;
  value: React.JSX.Element | string | null | undefined;
  onEdit?: () => void;
};

type KeyValueListProps = {
  items: KeyValueListItem[];
  px?: BoxProps['px'];
  py?: BoxProps['px'];
  borderBottomStyle?: BoxProps['borderBottomStyle'];
};

const KeyValueList = ({
  items,
  px = 'd6',
  py = 'd4',
  borderBottomStyle,
}: KeyValueListProps): React.JSX.Element => (
  <Ul borderBottomStyle={borderBottomStyle}>
    {items.map(({ key, value, onEdit }, index) => (
      <Li
        borderBottomWidth={index !== items.length - 1 ? 'borderWidth10' : 'borderWidth0'}
        key={key}
        keyName={key}
        px={px}
        py={py}
        value={
          <Box.div alignItems="center" display="flex" justifyContent="space-between" w="100%">
            {React.isValidElement(value) ? value : (value ?? '-')}
            {onEdit && (
              <Box.div>
                <Button
                  aria-label={`Edit ${key}`}
                  iconOnly
                  leadingIcon="square-pen"
                  onClick={onEdit}
                  variant="ghost"
                />
              </Box.div>
            )}
          </Box.div>
        }
      />
    ))}
  </Ul>
);

KeyValueList.Ul = Ul;
KeyValueList.Li = Li;
export { KeyValueList };
