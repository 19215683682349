import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { useCurrentUser } from 'config/CurrentUserContext';
import { trackPageView } from 'modules/analytics/analytics';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Box, Button, Heading } from '@localyze-pluto/components';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { ManageCompanyInformation } from './ManageCompanyInformation';
import { ManageOffices } from './ManageOffices';

export const ManageCompany = (): React.JSX.Element => {
  const { isHrAdmin, isAdmin } = useCurrentUser();

  const canEdit = isAdmin || isHrAdmin;
  const params = useParams<{ companyId: string }>();

  const { data: company } = useCompany({ companyId: Number(params.companyId) });

  useEffect(() => {
    trackPageView('Profile');
  }, []);

  const routes = useRoutes();

  return (
    <PageLayout
      maxW="1200px"
      mx="auto"
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          p="d0"
          to={routes.settings.get(params.companyId)}
          variant="ghost"
        >
          Back to Settings
        </Button>
      }
      w="100%"
    >
      <Box.div display="flex" flexDirection="column" gap="d2" marginBottom="d4" marginTop="d1">
        <Heading as="h1" marginBottom="d6" size="title-screen">
          {isAdmin ? 'Manage company profile' : 'Manage your company profile'}
        </Heading>
        {company ? (
          <>
            <ManageCompanyInformation canEdit={canEdit} company={company} />
            <ManageOffices />
          </>
        ) : (
          <ContainedLoadingState />
        )}
      </Box.div>
    </PageLayout>
  );
};
