import { Heading } from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { KeyValueList } from 'modules/caseDetails/components/KeyValueList/KeyValueList';
import { PartnerWithConfiguration } from 'modules/partners/hooks/usePartnersWithConfiguration';
import React from 'react';

export const PartnerInformation = ({
  partnerWithConfiguration,
}: {
  partnerWithConfiguration: PartnerWithConfiguration;
}): React.JSX.Element => {
  const items = [
    {
      key: `About ${partnerWithConfiguration.name}`,
      value: partnerWithConfiguration.about,
    },
    {
      key: 'Why Localyze is recommending this partner',
      value: partnerWithConfiguration.recommendation_reason,
    },
  ];
  return (
    <SectionCard>
      <SectionCard.Header py="d2">
        <Heading as="h2" fontSize="fontSize40" marginBottom="d0">
          Partner information
        </Heading>
      </SectionCard.Header>
      <SectionCard.Content padding="d0">
        <KeyValueList borderBottomStyle="none" items={items} py="d4" />
      </SectionCard.Content>
    </SectionCard>
  );
};
