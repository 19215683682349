import { ADMIN_ROUTES, AdminOnlyRoutes } from '../admin_routes';
import { COMMON_ROUTES, CommonRoute } from '../common_routes';
import { HR_ROUTES, HROnlyRoutes } from '../hr_routes';
import { Route } from 'types/Route';
import { useCurrentUser } from 'config/CurrentUserContext';

export type CommonRoutes = Record<CommonRoute, Route>;
export type AdminRoutes = AdminOnlyRoutes & CommonRoutes;
export type HrRoutes = CommonRoutes & HROnlyRoutes;

const getRoutes = ({ isAdmin }: { isAdmin: boolean }) => {
  return {
    ...COMMON_ROUTES,
    ...(isAdmin ? ADMIN_ROUTES : HR_ROUTES),
  };
};

export function useRoutes<T extends boolean>(): T extends true ? AdminRoutes : HrRoutes;
export function useRoutes(): AdminRoutes | HrRoutes {
  const { isAdmin } = useCurrentUser();

  return getRoutes({ isAdmin });
}
