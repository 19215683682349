import { Document } from 'modules/documents/api/attachments/types/Document';
import {
  DOCUMENT_URL_PREFIX,
  getPreviewDownloadDocUrl,
} from '../international/getPreviewDownloadDocUrl';

export const formatUrlByType = (document: Document, caseId: number): string => {
  return document.type === 'Attachment'
    ? `${DOCUMENT_URL_PREFIX}/attachments/${document.id}`
    : getPreviewDownloadDocUrl(String(document.internal_reference_code), caseId);
};
