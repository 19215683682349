import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { PartnerConfiguration } from 'modules/partners/types/PartnerConfiguration';
import { PARTNER_CONFIGURATIONS_QUERY_KEY } from '../usePartnerConfigurations/usePartnerConfigurations';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

type PropsCommon = {
  partnerId: number;
  companyId: number;
  serviceCategoryId: number;
};

type PropsWithDisabled = PropsCommon & {
  isDisabled: boolean;
  isPreferred?: never;
};

type PropsWithPreffered = PropsCommon & {
  isDisabled?: never;
  isPreferred: boolean;
};

export type CreatePartnerConfigurationProps = PropsWithDisabled | PropsWithPreffered;

export const createPartnerConfiguration = async ({
  partnerId,
  companyId,
  serviceCategoryId,
  isDisabled,
  isPreferred,
}: CreatePartnerConfigurationProps): Promise<PartnerConfiguration> => {
  const payload = {
    partner_configuration: {
      partner_id: partnerId,
      company_id: companyId,
      service_category_id: serviceCategoryId,
      ...(isDisabled !== undefined ? { disabled: String(isDisabled) } : {}),
      ...(isPreferred !== undefined ? { preferred_partner: isPreferred } : {}),
    },
  };

  const { data } = await axios.post<PartnerConfiguration>('/partner_configurations', payload);

  return data;
};

export const useCreatePartnerConfiguration = ({
  onError,
  onSuccess,
}: UseMutationOptions<
  PartnerConfiguration,
  AxiosErrorWithMessage,
  CreatePartnerConfigurationProps,
  'onError' | 'onSuccess'
>): UseMutationResult<
  PartnerConfiguration,
  AxiosErrorWithMessage,
  CreatePartnerConfigurationProps
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPartnerConfiguration,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [PARTNER_CONFIGURATIONS_QUERY_KEY],
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
