import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ControlledFormSelect,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Select,
  Table,
  TBody,
  Td,
  Text,
  Th,
  THead,
  Tr,
  useToast,
} from '@localyze-pluto/components';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DisclosureStore } from '@ariakit/react';
import React, { useState } from 'react';
import { Company } from 'modules/company/types/Company';
import { CaseSearchComboBox } from 'modules/billing/components/CaseSearchComboBox/CaseSearchComboBox';
import { CaseSearchValidation } from '../AddDebitModal/AddDebitModal';
import { useCreateAccountEntry } from 'modules/billing/hooks/useCreateAccountEntry/useCreateAccountEntry';
import { useAccountEntries } from 'modules/billing/hooks/useAccountEntries/useAccountEntries';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { AccountEntryType } from 'modules/billing/types/AccountEntryType';
import {
  CREDIT_PERCENTAGES,
  CREDIT_REASONS,
  PRODUCT_TYPE_SELECT_OPTIONS,
} from 'modules/billing/components/AddCreditModal/constants';
import { CreditReason } from 'modules/billing/types/CreditReason';
import { AccountEntry } from 'modules/billing/types/AccountEntry';
import { ProductType } from 'modules/billing/types/ProductType';
import { CaseSearchResult } from 'deprecated/types/talents/talentSearchResult';
import { CaseFormValue } from 'modules/billing/types/CaseFormValue';

type DebitPercentages = {
  id?: number;
  percentage?: string;
};

export type AddCreditModalFormTypes = {
  reason: CreditReason | '';
  kase: Maybe<CaseFormValue>;
  debitPercentages: DebitPercentages[];
};

const schema: Yup.ObjectSchema<AddCreditModalFormTypes> = Yup.object({
  reason: Yup.string()
    .oneOf(Object.values(CreditReason), 'Please select a reason for the credit.')
    .required(),
  kase: CaseSearchValidation,
  debitPercentages: Yup.array()
    .of(
      Yup.object({
        id: Yup.number(),
        percentage: Yup.string(),
      }),
    )
    .required(),
});

const availableCreditPercentages = (debit: AccountEntry) =>
  CREDIT_PERCENTAGES.map((creditOption) => {
    const newCreditAmount = debit.amount * parseFloat(creditOption.value);

    return {
      ...creditOption,
      disabled: (debit.credit_sum || 0) + newCreditAmount > debit.amount,
    };
  });

export const AddCreditModal = ({
  modalState,
  company,
  onSuccess,
}: {
  modalState: DisclosureStore;
  company: Company;
  onSuccess: () => void;
}): React.JSX.Element => {
  const toast = useToast();

  const [kase, setKase] = useState<Maybe<CaseSearchResult>>(null);
  const [productType, setProductType] = useState<ProductType | 'all'>('all');

  const { data, isPending } = useAccountEntries({
    params: {
      type: AccountEntryType.Debit,
      talentId: kase?.talent_id,
      companyId: company.id.toString(),
      productType,
      by_status: ['needs_review', 'reviewed', 'synced'],
    },
  });

  const debitPercentages: DebitPercentages[] =
    data?.account_entries.map((entry) => {
      return {
        id: entry.id,
        percentage: '0',
      };
    }) || [];

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    values: {
      reason: '',
      kase: null,
      debitPercentages,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
    mode: 'onTouched',
  });

  const { mutate: createAccountEntry, isPending: isCreateAccountEntryPending } =
    useCreateAccountEntry({
      onSuccess: () => {
        reset();
        modalState.hide();
        toast('The credits were created successfully', 'success');
        onSuccess();
      },
      onError: (error) => {
        toast(error.response?.data.message || 'There was an error creating the credits.', 'error');
      },
    });

  const onSubmit = (values: AddCreditModalFormTypes) => {
    const debitsToCredit = values.debitPercentages.filter((debit) => debit.percentage !== '0');

    debitsToCredit.forEach((debit) => {
      const accountEntry = data?.account_entries.find((entry) => entry.id === debit.id);

      if (!accountEntry) return;

      createAccountEntry({
        type: AccountEntryType.Credit,
        reference_entry_id: accountEntry.id,
        amount_cents: accountEntry.amount * Number(debit.percentage) * 100,
        description: accountEntry.description,
        credit_reason: values.reason,
      });
    });
  };

  return (
    <Modal store={modalState}>
      <ModalHeader>
        <ModalHeading>Add Credit</ModalHeading>
        <Text.span color="colorIconWeak" fontSize="fontSize20">
          {company.name}
        </Text.span>
      </ModalHeader>
      <ModalBody>
        <Box.form id="add-credit-form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledFormSelect
            control={control}
            id="reason"
            items={CREDIT_REASONS}
            label="Why do you want to add a credit?"
            name="reason"
            placeholder="Credit reason"
            required
          />
          <Box.div marginBottom="m4">
            <Label htmlFor="productType">Product type (optional)</Label>
            <Select
              items={PRODUCT_TYPE_SELECT_OPTIONS}
              name="productType"
              placeholder="Product type"
              setValue={(value) =>
                setProductType(
                  (typeof value === 'object' ? value[0] : value) as ProductType | 'all',
                )
              }
              title="Product type"
              value={productType}
            />
          </Box.div>
          <Box.div marginBottom="m4">
            <Label htmlFor="kase">Talent (optional)</Label>
            <Controller
              control={control}
              name="kase"
              render={({ field: { onChange, value } }) => (
                <CaseSearchComboBox
                  companyId={company.id}
                  displayCases={false}
                  onChange={(option) => {
                    onChange(option);
                    setKase(option);
                  }}
                  value={value}
                />
              )}
            />
          </Box.div>
          <Box.div flex="1 1 auto" h="100%" overflowY="auto">
            <Table>
              <THead>
                <Tr>
                  <Th>Debits</Th>
                  <Th />
                </Tr>
              </THead>
              <TBody>
                <>
                  {isPending ? (
                    <Tr>
                      <Td colSpan={2}>
                        <ContainedLoadingState />
                      </Td>
                    </Tr>
                  ) : (
                    data?.account_entries.map((debit, index) => (
                      <Tr key={debit.id}>
                        <Td>
                          {/* TODO: remove padding bottom after we remove
                           the extra padding from the formSelect in Pluto */}
                          <Box.div paddingBottom="p5">{debit.description}</Box.div>
                        </Td>
                        <Td>
                          <ControlledFormSelect
                            control={control}
                            id={`debitPercentages.${index}.percentage`}
                            items={availableCreditPercentages(debit)}
                            label=""
                            name={`debitPercentages.${index}.percentage`}
                          />
                        </Td>
                      </Tr>
                    ))
                  )}
                </>
              </TBody>
            </Table>
          </Box.div>
        </Box.form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            reset();
            modalState.toggle();
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={isCreateAccountEntryPending}
          form="add-credit-form"
          loading={isCreateAccountEntryPending}
          type="submit"
          variant="primary"
        >
          Add Credit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
