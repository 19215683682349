import React from 'react';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { Box, Button, Heading, Search, Select, useModalStore } from '@localyze-pluto/components';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { useHistory, useParams } from 'react-router-dom';
import { DataTable } from 'components/DataTable/DataTable';
import { AccountEntry } from '../types/AccountEntry';
import { AddDebitModal } from '../components/AddDebitModal/AddDebitModal';
import { usePagination } from 'modules/filters/hooks/usePagination';
import { useAccountEntries } from 'modules/billing/hooks/useAccountEntries/useAccountEntries';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { AddCreditModal } from '../components/AddCreditModal/AddCreditModal';
import { BILLING_DETAILS_COLUMNS } from 'modules/billing/utils/billingDetailsColumns';
import { useParameterFilter } from 'modules/filters/hooks/useParameterFilter';
import { useSearch } from 'modules/filters/hooks/useSearch';
import { useBillingPeriods } from 'modules/billing/hooks/useBillingPeriods/useBillingPeriods';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';
import isEmpty from 'lodash/isEmpty';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';
import { PaginationWithCounter } from 'components/PaginationWithCounter/PaginationWithCounter';
import { PRODUCT_TYPE_SELECT_OPTIONS } from '../components/AddCreditModal/constants';

export const BillingDetails = (): React.JSX.Element => {
  const history = useHistory();
  const addDebitModalState = useModalStore();
  const addCreditModalState = useModalStore();

  const isAddDebitModalOpen = addDebitModalState.useState('open');
  const isAddCreditModalOpen = addCreditModalState.useState('open');

  const { id } = useParams<{
    id: string;
  }>();
  const { data: company, isSuccess: isCompanySuccess } = useCompany({
    companyId: Number(id),
  });

  const { queryParams } = useQueryParams();
  const [page, setPage] = usePagination();
  const { search, onSearch, debouncedSearch } = useSearch();
  const [productType, setProductType] = useParameterFilter('product_type');
  const [billingPeriodId, setBillingPeriodId] = useParameterFilter('billing_period_id');

  const {
    data: accountEntriesResponse,
    isPending,
    isSuccess: isAccountEntriesSuccess,
    refetch: refetchAccountEntries,
  } = useAccountEntries({
    params: {
      page,
      productType,
      search: debouncedSearch,
      companyId: id,
      billingPeriodId,
      customerType: 'Company',
    },
  });

  const { isSuccess, data } = useBillingPeriods();

  const billingPeriodOptions = isSuccess
    ? mapToSelectOptions({
        records: data,
        labelProp: 'name',
        valueProp: 'id',
        allLabel: 'All billing periods',
      })
    : [];

  return (
    <>
      <PageLayout
        flexed
        titleButton={
          <Button
            leadingIcon="arrow-left"
            onClick={() => {
              let billingPeriod = '';
              if (queryParams.billing_period_id) {
                billingPeriod = `?billing_period_id=${queryParams.billing_period_id}`;
              }

              return history.push(`/billing${billingPeriod}`);
            }}
            variant="ghost"
          >
            Back to Billing
          </Button>
        }
      >
        <Box.div alignItems="center" display="flex" justifyContent="space-between" w="100%">
          <Box.div>
            <Heading size="heading40">{isCompanySuccess ? company.name : 'Loading...'}</Heading>
          </Box.div>
          <Box.div spaceX="p2">
            <Button
              as="a"
              href={
                `https://www.notion.so/localyze/` +
                `Credit-Policy-fee77f88cdf04d859d114401f2469506?pvs=4`
              }
              leadingIcon="info"
              target="_blank"
              variant="ghost"
            >
              Credit policy
            </Button>
            <Button
              leadingIcon="circle-plus"
              onClick={addCreditModalState.show}
              variant="secondary"
            >
              Add credit
            </Button>
            <Button leadingIcon="circle-plus" onClick={addDebitModalState.show} variant="secondary">
              Add debit
            </Button>
          </Box.div>
        </Box.div>
        <Box.div display="flex" paddingBottom="p2">
          <Box.div display="flex" gap="d2">
            <Box.div className="pluto-search" minWidth="200px">
              <Search
                onChange={onSearch}
                placeholder="Search talent"
                title="Search talent"
                value={search}
              />
            </Box.div>
            <Select
              disabled={!isSuccess}
              items={billingPeriodOptions}
              placeholder={isSuccess ? 'Billing periods' : 'Loading billing periods...'}
              setValue={(value) => setBillingPeriodId(typeof value === 'object' ? value[0] : value)}
              value={billingPeriodId}
            />
            <Box.div minWidth="160px">
              <Select
                items={PRODUCT_TYPE_SELECT_OPTIONS}
                placeholder="Product type"
                setValue={(value) => setProductType(typeof value === 'object' ? value[0] : value)}
                title="Product type"
                value={productType}
              />
            </Box.div>
          </Box.div>
        </Box.div>
        {isPending ? (
          <ContainedLoadingState />
        ) : !isAccountEntriesSuccess ? (
          <ErrorStatePage />
        ) : (
          <Box.div marginBottom="m4">
            <DataTable<AccountEntry, AccountEntry>
              columns={BILLING_DETAILS_COLUMNS}
              data={accountEntriesResponse.account_entries}
              emptyStateText="No account entries"
              isRowClickable
            />
          </Box.div>
        )}
        {isAccountEntriesSuccess && !isEmpty(accountEntriesResponse.account_entries) && (
          <PaginationWithCounter paginationInfo={accountEntriesResponse.meta} setPage={setPage} />
        )}
      </PageLayout>
      {isCompanySuccess && isAddDebitModalOpen && (
        <AddDebitModal
          company={company}
          modalState={addDebitModalState}
          onDebitCreation={refetchAccountEntries}
        />
      )}
      {isCompanySuccess && isAddCreditModalOpen && (
        <AddCreditModal
          company={company}
          modalState={addCreditModalState}
          onSuccess={refetchAccountEntries}
        />
      )}
    </>
  );
};
