import { Filter } from 'modules/filters/types/Filter';
import { FilterOption } from 'modules/filters/types/FilterOption';
import { getCaseManagers } from 'modules/users/api/getCaseManagers/getCaseManagers';
import { User } from 'deprecated/api/user/types/User';

const caseManagersToFilterOptions = (caseManagers: User[]): FilterOption<string>[] =>
  caseManagers.map((item) => ({
    filterAttribute: 'case_manager_id',
    label: `${item.first_name} ${item.last_name}`,
    userTypes: ['admin'],
    value: item.id.toString(),
  }));

const ALL_OPTION: FilterOption<string> = {
  filterAttribute: 'case_manager_id',
  label: 'All',
  userTypes: ['admin'],
  value: 'all',
};

const UNASSIGNED_OPTION: FilterOption<string> = {
  filterAttribute: 'case_manager_id',
  label: 'Unassigned',
  userTypes: ['admin'],
  value: 'unassigned',
};

export const CASE_MANAGER_FILTER: Filter<string> = {
  attribute: 'case_manager_id',
  label: 'Case Manager',
  options: [],
  populateOptions: async () => [
    ALL_OPTION,
    UNASSIGNED_OPTION,
    ...caseManagersToFilterOptions(await getCaseManagers({ withCases: true })),
  ],
  tabs: ['all'],
  type: 'multiselect',
  userTypes: ['admin'],
};
