import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { PartnerConfiguration } from 'modules/partners/types/PartnerConfiguration';

const STALE_TIME_5_MINUTES = 5 * 60 * 1000;
export const PARTNER_CONFIGURATIONS_QUERY_KEY = 'partner-configurations';

type GetPartnerConfigurationsParams = {
  companyId?: number;
  serviceCategoryId?: number;
  partnerId?: number;
};

const getPartnerConfigurations = async ({
  companyId,
  serviceCategoryId,
  partnerId,
}: GetPartnerConfigurationsParams): Promise<PartnerConfiguration[]> => {
  const { data } = await axios.get<PartnerConfiguration[]>('/partner_configurations', {
    params: {
      company_id: companyId,
      service_category_id: serviceCategoryId,
      partner_id: partnerId,
    },
  });

  return data;
};

export const usePartnerConfigurations = ({
  serviceCategoryId,
  partnerId,
  companyId,
  enabled,
}: GetPartnerConfigurationsParams &
  Pick<UseQueryOptions<PartnerConfiguration[]>, 'enabled'>): UseQueryResult<
  PartnerConfiguration[]
> => {
  return useQuery({
    queryKey: [PARTNER_CONFIGURATIONS_QUERY_KEY, serviceCategoryId, companyId, partnerId],
    queryFn: () => getPartnerConfigurations({ serviceCategoryId, companyId, partnerId }),
    staleTime: STALE_TIME_5_MINUTES,
    enabled,
  });
};
