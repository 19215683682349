import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CreateCase } from 'modules/cases/pages/createCase/CreateCase';
import { ErrorBoundaryFallback } from 'modules/layout/components/ErrorBoundaryFallback';
import { HomePage } from 'deprecated/pages/viewsNew/ChewpacaLayout/Home/HomePage';
import { Knowledge } from 'deprecated/pages/viewsNew/ChewpacaLayout/Knowledge/Knowledge';
import React from 'react';
import { TasksList } from 'deprecated/pages/viewsNew/ChewpacaLayout/Tasks/TasksList';
import { Team } from 'modules/company/pages/Team/Team';
import { UserProfile } from 'modules/users/pages/UserProfile/UserProfile';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from '@rollbar/react';
import { Sidebar } from 'modules/layout/components/Sidebar';
import { AdminRoutes } from 'modules/layout/components/AdminRoutes';
import { HRRoutes } from 'modules/layout/components/HRRoutes';
import { CaseOverviews } from 'modules/caseOverview/pages/CaseOverviews';
import { CaseDetailsPage } from 'modules/caseDetails/pages/CaseDetailsPage';
import { TalentProfile } from 'modules/talent/pages/talentProfile/TalentProfile';
import { TalentContextProvider } from 'modules/talent/pages/talentProfile/components/TalentContext/TalentContextProvider';
import { useCurrentUser } from 'config/CurrentUserContext';
import { TalentList } from 'modules/talent/pages/talentList/TalentList';
import { Company } from 'modules/company/pages/Company';
import { AddOffice } from 'modules/company/pages/AddOffice/AddOffice';
import { Settings } from 'modules/settings/pages/Settings';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { BillingAndInvoices } from 'modules/billing/pages/BillingAndInvoices/BillingAndInvoices';
import { ManageCompany } from 'modules/company/pages/ManageCompany/ManageCompany';

export const AppLayout = (): React.JSX.Element => {
  const { reset } = useQueryErrorResetBoundary();
  const location = useLocation();
  const isPartnersHubActive = useIsFeatureFlagActive('partners_hub');

  const routes = useRoutes();

  const { isHrViewer, isAdmin, company_id } = useCurrentUser();
  const userCanCreateCases = !isHrViewer;

  return (
    <div className="tw-min-h-screen tw-flex tw-bg-blue-0" data-testid="chewpaca-layout">
      <Sidebar />
      <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-auto">
        <ErrorBoundary
          fallbackUI={() => <ErrorBoundaryFallback resetErrorBoundary={reset} />}
          key={location.pathname}
        >
          <Switch>
            {/* TODO Temporary redirects to e.g. keep bookmarks working, remove after 01.01.2025 */}
            {isAdmin && <Redirect exact from="/admin/talent/:id" to={routes.talentProfile.url} />}
            {isAdmin && <Redirect exact from="/admin/tasks" to={routes.tasks.url} />}
            {isAdmin && <Redirect exact from="/admin/team" to={routes.team.get(company_id)} />}
            {isAdmin && <Redirect exact from="/admin/knowledge" to={routes.knowledge.url} />}
            {!isAdmin && <Redirect exact from="/company/offices" to={routes.offices?.url || '/'} />}
            {!isAdmin && (
              <Redirect exact from="/company/integrations" to={routes.integrations?.url || '/'} />
            )}
            {!isAdmin && <Redirect exact from="/company/reporting" to={routes.reporting.url} />}
            {!isAdmin && (
              <Redirect exact from="/company/talent/:id" to={routes.talentProfile.url} />
            )}
            {!isAdmin && <Redirect exact from="/company/tasks" to={routes.tasks.url} />}
            {!isAdmin && <Redirect exact from="/company/team" to={routes.team.get(company_id)} />}
            {!isAdmin && <Redirect exact from="/company/knowledge" to={routes.knowledge.url} />}
            {!isAdmin && (
              <Redirect exact from="/company/profile" to={routes.companyProfile.get(company_id)} />
            )}
            <Route exact key={routes.home.url} path={'/'} render={() => <HomePage />} />
            <Route
              exact
              key={routes.talentProfile.url}
              path={routes.talentProfile.url}
              render={() => (
                <TalentContextProvider>
                  <TalentProfile />
                </TalentContextProvider>
              )}
            />
            {/* This can be removed in a few months after the release, so e.g. in the beginning
             of 2025 */}
            <Redirect exact from="/admin/talent/:id" to={routes.talentProfile.url} />
            <Redirect exact from="/company/talent/:id" to={routes.talentProfile.url} />
            <Redirect
              exact
              from="/admin/talent"
              to={{
                pathname: routes.cases.url,
                search: location.search,
              }}
            />
            <Route
              exact
              key={routes.cases.url}
              path={routes.cases.url}
              render={() => <CaseOverviews />}
            />

            <Route key={routes.tasks.url} path={routes.tasks.url} render={() => <TasksList />} />

            <Route
              key={routes.talents.url}
              path={routes.talents.url}
              render={() => <TalentList />}
            />
            <Route
              key={routes.userProfile.url}
              path={routes.userProfile.url}
              render={() => <UserProfile />}
            />

            <Route key={routes.team.url} path={routes.team.url} render={() => <Team />} />
            {/* TODO: remove redirect after 01.01.2025 */}
            <Redirect exact from="/team" to={routes.team.get(company_id)} />

            <Route
              key={routes.knowledge.url}
              path={routes.knowledge.url}
              render={() => <Knowledge />}
            />

            {userCanCreateCases && (
              <Route
                key={routes.createCase.url}
                path={routes.createCase.url}
                // Use key to force refresh when creating another case from create case page
                render={(props) => <CreateCase key={props.location.key} />}
              />
            )}
            <Route
              exact
              key={routes.case.url}
              path={routes.case.url}
              render={() => <CaseDetailsPage />}
            />
            <Redirect
              exact
              from="/admin/localyze-profile"
              to={routes.companyProfile.get(company_id)}
            />
            <Route
              exact
              key={routes.companyProfile.url}
              path={routes.companyProfile.url}
              render={() => <Company />}
            />
            <Route
              exact
              key={routes.addOffice.url}
              path={routes.addOffice.url}
              render={() => <AddOffice />}
            />
            {isPartnersHubActive && (
              <Route
                exact
                key={routes.settings.url}
                path={routes.settings.url}
                render={() => <Settings />}
              />
            )}
            {isPartnersHubActive && (
              <Route
                exact
                key={routes.companyBilling.url}
                path={routes.companyBilling.url}
                render={() => <BillingAndInvoices />}
              />
            )}
            {isPartnersHubActive && (
              <Route
                exact
                key={routes.manageCompany.url}
                path={routes.manageCompany.url}
                render={() => <ManageCompany />}
              />
            )}
            {isAdmin ? <AdminRoutes /> : <HRRoutes />}
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  );
};
