import {
  Badge,
  Box,
  Button,
  ContentCard,
  Heading,
  InteractiveElementType,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDrawerStore,
} from '@localyze-pluto/components';
import { useServiceCategories } from 'modules/additionalServices/api/useServiceCategories/useServiceCategories';
import { PageLayout } from 'modules/layout/components/PageLayout';
import React, { useEffect, useState } from 'react';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { PartnerSettingsDrawer } from './components/PartnerSettingsDrawer/PartnerSettingsDrawer';
import { useStoreState } from '@ariakit/react';
import { usePartnersWithConfiguration } from '../hooks/usePartnersWithConfiguration';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useTabWithQueryParams } from 'deprecated/hooks/custom/useTabWithQueryParams';
import { Tab as TabType } from 'types/Tab';

export const ManagePartners = (): React.JSX.Element => {
  const { companyId } = useParams<{
    companyId: string;
  }>();
  const location = useLocation();

  const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState<number>();
  const [serviceCategoriesTabs, setServiceCategoriesTabs] = useState<TabType<string>[]>();

  const { data: serviceCategories, isSuccess: isSuccessCategories } = useServiceCategories();

  const { partnersWithConfiguration, isSuccess: isSuccessPartnersWithConfiguration } =
    usePartnersWithConfiguration({
      serviceCategoryId: selectedServiceCategoryId,
      companyId: Number(companyId),
      enabled: !!selectedServiceCategoryId,
    });

  const routes = useRoutes();

  const { tabs, selectedTab, getTabIndex, updateSelectedTab } = useTabWithQueryParams(
    serviceCategoriesTabs || [],
  );

  useEffect(() => {
    if (isSuccessCategories) {
      setServiceCategoriesTabs(
        serviceCategories.map((serviceCategory) => ({
          key: serviceCategory.id.toString(),
          name: serviceCategory.name,
        })),
      );
    }
  }, [isSuccessCategories, serviceCategories]);

  useEffect(() => {
    if (tabs.length > 0) {
      setSelectedServiceCategoryId(Number(selectedTab.key));
    }
  }, [selectedTab, tabs]);

  const drawerStore = useDrawerStore();
  const isDrawerOpen = useStoreState(drawerStore, 'open');

  if (!isSuccessCategories || tabs.length === 0) {
    return <ContainedLoadingState />;
  }

  return (
    <PageLayout
      flexed
      maxW="1200px"
      mx="auto"
      px="d16"
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          p="d0"
          to={routes.settings.get(companyId)}
          variant="ghost"
        >
          Back to Settings
        </Button>
      }
      w="100%"
    >
      <Box.div display="flex" justifyContent="space-between">
        <Heading as="h1" marginBottom="d6" size="title-screen">
          Manage your partners
        </Heading>
        <Button
          leadingIcon="circle-help"
          marginBottom="d6"
          onClick={drawerStore.show}
          variant="ghost"
        >
          Learn more
        </Button>
      </Box.div>
      <Tabs initialTabId={selectedTab.key}>
        <TabList aria-label="Service Categories Tabs" withDivider>
          {tabs.map((tab) => (
            <Tab
              id={tab.key}
              key={tab.key}
              onClick={() => {
                setSelectedServiceCategoryId(Number(tab.key));
                updateSelectedTab(getTabIndex(tabs, tab.key));
              }}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>
        {isSuccessPartnersWithConfiguration ? (
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.key} tabId={tab.key} unmountOnHide>
                <Box.div
                  display="grid"
                  gap="d3"
                  gridAutoRows="1fr"
                  gridTemplateColumns={{
                    _: 'repeat(2, 1fr)',
                    xl: 'repeat(3, 1fr)',
                  }}
                  my="d8"
                >
                  {partnersWithConfiguration.map((partnerWithConfiguration) => {
                    const isDisabled = partnerWithConfiguration.isDisabled;
                    const isPreferred = partnerWithConfiguration.isPreferred;

                    const partnerRoute = routes.partner?.getWithSearch?.(
                      partnerWithConfiguration.id,
                      companyId,
                      Number(tab.key),
                    );

                    return (
                      <Link
                        key={partnerWithConfiguration.id}
                        to={{
                          search: partnerRoute?.search || '',
                          pathname: partnerRoute?.pathname || '/',
                          state: { from: { pathname: location.pathname, search: location.search } },
                        }}
                      >
                        <ContentCard
                          background="inverse"
                          hasLowOpacity={isDisabled}
                          imageAlt=""
                          imagePosition="top"
                          imageSrc={partnerWithConfiguration.image_url}
                          interactiveElementType={InteractiveElementType.Card}
                          key={partnerWithConfiguration.id}
                          text={partnerWithConfiguration.description}
                          title={partnerWithConfiguration.name}
                          topLeftElement={
                            <Box.div
                              alignItems="center"
                              display="flex"
                              h={40}
                              overflow="hidden"
                              w={40}
                            >
                              <Box.img
                                aria-hidden
                                h="auto"
                                src={partnerWithConfiguration.logo_url}
                                w="100%"
                              />
                            </Box.div>
                          }
                          topRightElement={
                            isPreferred || isDisabled ? (
                              <Box.div position="absolute" right={24} top={24}>
                                {isPreferred && <Badge icon="star">Preferred partner</Badge>}
                                {isDisabled && <Badge color="red">Disabled</Badge>}
                              </Box.div>
                            ) : null
                          }
                        />
                      </Link>
                    );
                  })}
                </Box.div>
              </TabPanel>
            ))}
          </TabPanels>
        ) : (
          <ContainedLoadingState />
        )}
      </Tabs>
      {isDrawerOpen && <PartnerSettingsDrawer state={drawerStore} />}
    </PageLayout>
  );
};
