import {
  Badge,
  Box,
  Button,
  Heading,
  Icon,
  Menu,
  ConfirmationModal,
  useToast,
  useModalStore,
} from '@localyze-pluto/components';
import { useCreatePartnerConfiguration } from 'modules/partners/api/useCreatePartnerConfiguration/useCreatePartnerConfiguration';
import { useDeletePartnerConfiguration } from 'modules/partners/api/useDeletePartnerConfiguration/useDeletePartnerConfiguration';
import { PartnerWithConfiguration } from 'modules/partners/hooks/usePartnersWithConfiguration';
import React from 'react';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

export type PartnerPreferencesHeaderProps = {
  partnerWithConfiguration: PartnerWithConfiguration;
  serviceCategoryId: number;
  companyId: number;
  preferredConfigurations?: PartnerWithConfiguration[];
};

export const PartnerPreferencesHeader = ({
  partnerWithConfiguration,
  serviceCategoryId,
  companyId,
  preferredConfigurations,
}: PartnerPreferencesHeaderProps): React.JSX.Element => {
  const toast = useToast();
  const confirmationModalStore = useModalStore();

  const onUpdateSucces = () => toast('Partner preferences updated', 'success');
  const onUpdateError = (error: AxiosErrorWithMessage) =>
    toast(`Failed to update partner preferences. ${error.response?.data.message}.`, 'error');

  const { mutate: createPartnerConfiguration, isPending: isPendingCreation } =
    useCreatePartnerConfiguration({
      onSuccess: onUpdateSucces,
      onError: onUpdateError,
    });

  const { mutate: deletePartnerConfiguration, isPending: isPendingDeletion } =
    useDeletePartnerConfiguration({
      onSuccess: onUpdateSucces,
      onError: onUpdateError,
    });

  const isPendingRequest = isPendingDeletion || isPendingCreation;
  const isDisabled = !!partnerWithConfiguration.isDisabled;
  const isPreferred = !!partnerWithConfiguration.isPreferred;
  const serviceCategoryName = partnerWithConfiguration.serviceCategoryName?.toLowerCase();
  const partnerName = partnerWithConfiguration.name;
  const preferredPartnerName = preferredConfigurations?.[0]?.name;

  let badgeText;

  if (isPreferred) {
    badgeText = `Preferred partner for ${serviceCategoryName}`;
  } else if (isDisabled) {
    badgeText = `Disabled for ${serviceCategoryName}`;
  } else {
    badgeText = '';
  }

  const createConfiguration = () => {
    createPartnerConfiguration({
      partnerId: partnerWithConfiguration.id,
      serviceCategoryId,
      companyId,
      isPreferred: true,
    });
  };

  return (
    <Box.section display="flex" flexDirection="column" gap="d3">
      <Box.div alignItems="center" display="flex" justifyContent="space-between">
        {badgeText ? (
          <Badge
            size="small"
            {...(isDisabled && { color: 'red' })}
            {...(isPreferred && { icon: 'star' })}
          >
            {badgeText}
          </Badge>
        ) : (
          <div />
        )}
        <Menu
          items={[
            {
              disabled: isDisabled || isPendingRequest,
              label: (
                <Box.div alignItems="center" display="flex" gap="d2">
                  <Icon
                    as="span"
                    decorative={true}
                    icon={isPreferred ? 'star-off' : 'star'}
                    size="sizeIcon20"
                  />
                  <Box.span>{isPreferred ? 'Remove' : 'Set'} as preferred partner</Box.span>
                </Box.div>
              ),
              onClick: () => {
                if (isPreferred) {
                  return deletePartnerConfiguration({
                    partnerConfigurationId: Number(partnerWithConfiguration.configurationId),
                  });
                }

                if (preferredConfigurations?.length) {
                  confirmationModalStore.show();
                } else {
                  createConfiguration();
                }
              },
            },
            {
              label: (
                <Box.div alignItems="center" display="flex" gap="d2">
                  <Icon
                    as="span"
                    decorative={true}
                    icon={isDisabled ? 'circle-check' : 'circle-x'}
                    size="sizeIcon20"
                  />
                  <Box.span>{isDisabled ? 'Enable' : 'Disable'} partner</Box.span>
                </Box.div>
              ),
              onClick: () => {
                if (isDisabled) {
                  return deletePartnerConfiguration({
                    partnerConfigurationId: Number(partnerWithConfiguration.configurationId),
                  });
                }

                createPartnerConfiguration({
                  partnerId: partnerWithConfiguration.id,
                  serviceCategoryId,
                  companyId,
                  isDisabled: !isDisabled,
                });
              },
            },
          ]}
          menuButton={
            <Button
              aria-label="Set partner preferences"
              trailingIcon="chevron-down"
              variant="secondary"
            >
              Set preferences
            </Button>
          }
        />
      </Box.div>
      <Box.header
        alignItems="center"
        backgroundColor="locaMarine200"
        borderRadius="borderRadius40"
        display="flex"
        gap="d6"
        padding="d8"
      >
        <Box.div
          alignItems="center"
          backgroundColor="emojiWrapperBgInverse"
          borderRadius="borderRadius40"
          display="flex"
          h={88}
          justifyContent="center"
          overflow="hidden"
          padding="d2"
          w={88}
        >
          <Box.img aria-hidden maxH="100%" maxW="100%" src={partnerWithConfiguration.logo_url} />
        </Box.div>
        <Box.div display="flex" flexDirection="column">
          <Heading as="h2" marginBottom="d0" size="title-group">
            {partnerWithConfiguration.serviceCategoryName} services by
          </Heading>
          <Heading as="h1" marginBottom="d0" size="title-screen">
            {partnerWithConfiguration.name}
          </Heading>
        </Box.div>
      </Box.header>
      <ConfirmationModal
        confirmLabel="Confirm"
        onConfirm={createConfiguration}
        store={confirmationModalStore}
      >
        You can only select one preferred partner for {serviceCategoryName}. You have currently
        chosen <Box.strong>{preferredPartnerName}</Box.strong> as your preferred partner. If you
        confirm, <Box.strong>{partnerName}</Box.strong> will replace {preferredPartnerName} as your
        preferred partner for <Box.strong>{serviceCategoryName}</Box.strong>.
      </ConfirmationModal>
    </Box.section>
  );
};
