import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Icon,
  InteractiveElementTypeUtilityCard,
  useDrawerStore,
  useModalStore,
  useToast,
  UtilityCard,
} from '@localyze-pluto/components';
import type { CaseAdditionalService } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { useCaseAdditionalServices } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import { ServiceDrawer } from '../ServiceDrawer/ServiceDrawer';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { startCase } from 'lodash';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { FAMILY_MEMBERS_SERVICE_NAME } from 'modules/additionalServices/constants/familyMembersServiceName';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { EditAdditionalServicesModal } from 'modules/additionalServices/components/EditAdditionalServicesModal/EditAdditionalServicesModal';

type CaseAdditionalServicesProps = {
  caseId: number;
  companyId?: number;
  destinationCountryId?: number;
};

export const CaseAdditionalServices: React.FC<CaseAdditionalServicesProps> = ({
  caseId,
  companyId,
  destinationCountryId,
}) => {
  const { Content, Header } = SectionCard;
  const [serviceDrawerData, setServiceDrawerData] = useState<CaseAdditionalService>();
  const drawerState = useDrawerStore();
  const isDrawerOpen = drawerState.useState('open');
  const toast = useToast();
  const modalState = useModalStore();
  const isModalOpen = modalState.useState('open');
  const {
    data: caseAdditionalServices,
    isSuccess,
    isError,
    error,
  } = useCaseAdditionalServices({
    caseId,
  });

  if (isError) toast(error.message || 'Error fetching additional services.', 'error');

  if (!isSuccess) {
    return <ContainedLoadingState />;
  }

  const handleClick = (caseAdditionalService: CaseAdditionalService) => {
    return () => {
      drawerState.toggle();
      setServiceDrawerData(caseAdditionalService);
      trackEvent('additional service: additional service details viewed');
    };
  };

  const caseAdditionalServicesWithoutFamily = caseAdditionalServices.filter(
    (caseAdditionalService) =>
      caseAdditionalService.additional_service.name !== FAMILY_MEMBERS_SERVICE_NAME,
  );

  const serviceDrawerHeading = `${startCase(
    serviceDrawerData?.additional_service.service_category.name || '',
  )}
  - ${startCase(serviceDrawerData?.additional_service.name || '')}`;

  return (
    <SectionCard>
      <Header>
        <Box.div
          alignItems="center"
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Heading as="h3" marginBottom="d0" size="heading60">
            Additional Services
          </Heading>
          <Button
            leadingIcon="pen-square"
            onClick={modalState.show}
            type="button"
            variant="secondary"
          >
            Edit
          </Button>
        </Box.div>
      </Header>
      <Content flexDirection="column" justifyContent="flex-start" maxH={600} padding="p0">
        {caseAdditionalServicesWithoutFamily.length === 0 ? (
          <EmptyState
            gap="d2"
            illustration={
              <Icon color="contentTertiary" decorative icon="file-plus-2" title="File Plus Icon" />
            }
            text="No additional services booked."
          />
        ) : (
          <Box.ul display="flex" flexDirection="column" gap="d2" marginBottom="m0" padding="p4">
            {caseAdditionalServicesWithoutFamily.map((caseAdditionalService) => (
              <UtilityCard
                as="li"
                categoryTag={startCase(
                  caseAdditionalService.additional_service.service_category.name,
                )}
                emoji={startCase(caseAdditionalService.additional_service.service_category.emoji)}
                interactiveElementType={InteractiveElementTypeUtilityCard.Card}
                key={caseAdditionalService.id}
                onClick={handleClick(caseAdditionalService)}
                status={startCase(caseAdditionalService.status)}
                title={startCase(caseAdditionalService.additional_service.name)}
              />
            ))}
          </Box.ul>
        )}
      </Content>
      {isDrawerOpen && serviceDrawerData && (
        <ServiceDrawer
          caseAdditionalServiceId={serviceDrawerData.id}
          companyId={companyId}
          destinationCountryId={destinationCountryId}
          heading={serviceDrawerHeading}
          state={drawerState}
        />
      )}
      {isModalOpen && <EditAdditionalServicesModal caseId={caseId} modalState={modalState} />}
    </SectionCard>
  );
};
