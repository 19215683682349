import React from 'react';
import { Box, Button, ButtonProps } from '@localyze-pluto/components';
import { UseFormReset } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form';

type Props<T extends FieldValues> = {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  reset: UseFormReset<T>;
  variant?: ButtonProps['variant'];
};

export const EditButton = <T extends FieldValues>({
  isEditing,
  setIsEditing,
  reset,
  variant = 'secondary',
}: Props<T>): React.JSX.Element => (
  <>
    {isEditing ? (
      <Box.div display="flex" gap="d4">
        <Button type="submit" variant="primary">
          Save
        </Button>
        <Button
          onClick={() => {
            setIsEditing(false);
            reset();
          }}
          type="button"
          variant="secondary"
        >
          Cancel
        </Button>
      </Box.div>
    ) : (
      <Button
        leadingIcon="square-pen"
        onClick={() => setIsEditing(true)}
        type="button"
        variant={variant}
      >
        Edit
      </Button>
    )}
  </>
);
