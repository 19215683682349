import { Box, Search, Table, TBody } from '@localyze-pluto/components';

import { CompanyCard } from 'deprecated/pages/views/Admin/companies/CompanyCard/CompanyCard';
import CompanyStateDropDown from 'deprecated/components/DropDown/CompanyStateDropDown';
import { PageLayout } from 'modules/layout/components/PageLayout';
import React from 'react';
import _ from 'lodash';
import { companyStateOptions } from 'deprecated/utils/helper/admin/companies';
import { getCompaniesData } from 'deprecated/utils/helper/agency/companies';
import { trackPageView } from 'modules/analytics/analytics';
import { withRouter } from 'react-router-dom';
import { CreateCompanyModal } from 'modules/company/components/CreateCompanyModal/CreateCompanyModal';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { AdminCompaniesTableHeader } from './AdminCompaniesTableHeader';

class AdminCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      query: this.getInitialQuery(this.props),
      requestDone: false,
      requestDoneStatuses: false,
    };

    this.infinityScrollRef = React.createRef();
  }

  getInitialQuery = ({ location }) => {
    if (location.state) {
      const { paginationParams } = location.state;
      return {
        searchValue: paginationParams.searchValue || '',
        companyState: paginationParams.companyState || 'active',
        companyLastOpened: paginationParams.companyLastOpened || null,
        companyLastStatusProcess: paginationParams.companyLastStatusProcess,
      };
    }
    return {
      searchValue: '',
      companyState: 'active',
      companyLastOpened: null,
    };
  };

  componentDidMount() {
    trackPageView('Companies');
    this.loadCompaniesData();
    this.infinityScrollRef.current.addEventListener('scroll', this.handleScroll, true);
    companyStateOptions(
      (response) => {
        this.setState({ companyStateOptions: response.data, requestDoneStatuses: true });
      },
      (error) => console.log(error),
    );
  }

  componentWillUnmount = () =>
    this.infinityScrollRef.current.removeEventListener('scroll', this.handleScroll);

  handleScroll = (e) => {
    // Avoid opened modal to call scroll event and make http requests
    const classNameOfScrollContainer = e.target.className;
    const modalScrollContainerName = 'scrolling content';

    if (this.reachedPageBottom(e) && classNameOfScrollContainer !== modalScrollContainerName) {
      this.setState({ query: { ...this.state.query } });
      this.loadCompaniesData();
    }
  };

  reachedPageBottom = (e) => {
    return e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
  };

  updateCompanies = (companyNameCreated = '', companyState) => {
    companyNameCreated
      ? this.loadOneCompanyData(companyNameCreated, companyState)
      : this.firstLoad();
  };

  loadCompaniesData = () => {
    const { query } = this.state;
    getCompaniesData({ q: query.searchValue, company_state: query.companyState }, (response) => {
      this.setState({ companies: response.data, requestDone: true });
    });
  };

  searchByCompanyName = (event) => {
    this.setState({ query: { ...this.state.query, searchValue: event.target.value } }, () =>
      this.loadCompaniesData(),
    );
  };

  loadOneCompanyData = (companyName, companyState) => {
    this.setState({
      requestDone: false,
      query: {
        ...this.state.query,
        searchValue: companyName,
        companyState: companyState,
      },
    });
    getCompaniesData({ q: companyName, company_state: companyState }, (response) => {
      this.setState({ companies: [...response.data], requestDone: true });
    });
  };

  getCompanyCards = ({ companies, query }) => {
    if (!this.state.requestDone || !this.state.requestDoneStatuses) {
      return <ContainedLoadingState />;
    }

    return (
      <Table striped key="companies">
        <AdminCompaniesTableHeader />
        <TBody>
          {companies.map((company) => (
            <CompanyCard
              company={company}
              companyState={company.state}
              companyStateOptions={this.state.companyStateOptions}
              key={company.id}
              paginationParams={query}
              updateCompanies={this.updateCompanies}
            />
          ))}
        </TBody>
      </Table>
    );
  };

  firstLoad = () => {
    const { query } = this.state;
    getCompaniesData({ q: query.searchValue, company_state: query.companyState }, (response) => {
      this.setState({
        companies: response.data,
        query: { ...this.state.query, searchValue: '' },
      });
    });
  };

  selectCompanyState = (companyState) => {
    this.setState({ query: { ...this.state.query, companyState: companyState } }, () =>
      this.loadCompaniesData(),
    );
  };

  render() {
    return (
      <PageLayout
        flexed
        title="Companies"
        titleButton={<CreateCompanyModal onSuccess={this.loadCompaniesData} />}
      >
        <Box.div
          alignItems="flex-end"
          display="flex"
          gap="d4"
          justifyContent="space-between"
          marginBottom="d4"
        >
          <Box.span marginLeft="d1" minWidth="25%">
            <CompanyStateDropDown
              onChangeStatus={this.selectCompanyState}
              placeholder="Select a company state"
              value={this.state.query.companyState}
            />
          </Box.span>
          <Box.span marginRight="d1" minWidth="25%">
            <Search
              id="searchInput"
              onChange={_.debounce(this.searchByCompanyName, 1000, { leading: true })}
              placeholder="Search by company name"
              value={this.state.query.searchValue}
            />
          </Box.span>
        </Box.div>

        <Box.div
          overflow="auto"
          paddingBottom="d5"
          paddingTop="d0"
          px="d1"
          ref={this.infinityScrollRef}
          style={{
            height: 'calc(100vh - 220px)',
          }}
        >
          {this.getCompanyCards(this.state)}
        </Box.div>
      </PageLayout>
    );
  }
}

export default withRouter(AdminCompanies);
