import { Button, Heading } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';
import { BillingInformation } from 'modules/billing/components/BillingInformation/BillingInformation';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

export const BillingAndInvoices = (): React.JSX.Element => {
  const { companyId } = useParams<{ companyId?: string }>();
  const { isHrAdmin, isAdmin } = useCurrentUser();
  const { data: company, isLoading } = useCompany({
    companyId: Number(companyId),
    enabled: !!companyId,
  });

  const routes = useRoutes();

  if (isLoading || !company) {
    return <ContainedLoadingState />;
  }

  return (
    <PageLayout
      flexed
      maxW={1200}
      mx="auto"
      titleButton={
        <Button
          as={Link}
          leadingIcon="arrow-left"
          p="d0"
          to={routes.settings.get(companyId)}
          variant="ghost"
        >
          Back to Settings
        </Button>
      }
      w="100%"
    >
      <Heading as="h1" marginBottom="d7" size="title-screen">
        Billing and invoices
      </Heading>
      <BillingInformation canEdit={isHrAdmin || isAdmin} company={company} />
    </PageLayout>
  );
};
