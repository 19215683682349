import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { PartnerService } from 'modules/partners/types/PartnerService';

const STALE_TIME_5_MINUTES = 5 * 60 * 1000;

export type GetPartnerServicesParams = {
  serviceCategoryId?: number;
  partnerId?: number;
  additionalServiceId?: number;
  destinationCountryId?: number;
};

const getPartnerServices = async ({
  serviceCategoryId,
  partnerId,
  additionalServiceId,
  destinationCountryId,
}: GetPartnerServicesParams = {}): Promise<PartnerService[]> => {
  const { data } = await axios.get<PartnerService[]>('/partner_services', {
    params: {
      service_category_id: serviceCategoryId,
      partner_id: partnerId,
      additional_service_id: additionalServiceId,
      country_id: destinationCountryId,
    },
  });

  return data;
};

export const usePartnerServices = ({
  serviceCategoryId,
  partnerId,
  additionalServiceId,
  destinationCountryId,
  enabled,
}: GetPartnerServicesParams &
  Pick<UseQueryOptions<PartnerService[]>, 'enabled'> = {}): UseQueryResult<PartnerService[]> => {
  return useQuery({
    queryKey: [
      'partner-services',
      serviceCategoryId,
      partnerId,
      additionalServiceId,
      destinationCountryId,
    ],
    queryFn: () =>
      getPartnerServices({
        serviceCategoryId,
        partnerId,
        additionalServiceId,
        destinationCountryId,
      }),
    staleTime: STALE_TIME_5_MINUTES,
    enabled,
  });
};
