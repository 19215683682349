import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { identifyUser } from '../analytics';
import { getAnalyticsData } from './getAnalyticsData';
import { AnalyticsData } from './AnalyticsData';

export const setWindowDataLayer = (analyticsData: AnalyticsData): void => {
  const data = {
    internal_id: analyticsData.id,
    email: analyticsData.email,
    name: analyticsData.first_name,
    user_type: analyticsData.user_type,
    role: analyticsData.role_name,
    company: analyticsData.company,
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
  identifyUser(analyticsData.identifier, data, {
    integrations: {
      All: true,
      Intercom: {
        user_hash: analyticsData.user_hash,
      },
    },
  });
};

export const useAnalyticsData = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): UseQueryResult<AnalyticsData> => {
  return useQuery({
    queryKey: ['analytics'],
    queryFn: getAnalyticsData,
    enabled,
  });
};
