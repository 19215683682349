export const DOCUMENT_URL_PREFIX =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';

export const getPreviewDownloadDocUrl = (
  internalReferenceCode: string,
  caseId?: number,
): string => {
  const url = `${DOCUMENT_URL_PREFIX}/documents/${internalReferenceCode}`;
  return caseId ? `${url}?case_id=${caseId}` : url;
};
