import { Box, Td } from '@localyze-pluto/components';
import React from 'react';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import { GlomoRequestTableLink } from 'modules/glomoRequests/components/GlomoRequestTableLink';
import { GlomoRequestStatusBadge } from './GlomoRequestStatusBadge';
import { RiskLevel } from './RiskLevel';
import { formatGlomoRequestDate } from '../utils/formatGlomoRequestDate';
import { shouldShowNotification } from '../utils/shouldShowNotification';
import { TableRowCells } from '../types/TableRowCells';

type HrColumn =
  | 'ApprovedOrDeniedDate'
  | 'Name'
  | 'Notification'
  | 'RequestDate'
  | 'RequestType'
  | 'RiskLevel'
  | 'Status'
  | 'TravelStartDate';
type AdminColumn = HrColumn | 'AdminNotification' | 'Company';

export type ColumnId = AdminColumn | HrColumn;

const CompanyCell = ({ companyId }: { companyId: number }) => {
  const { data: company } = useCompany({ companyId });

  return <>{company?.name || ''}</>;
};

const paddings: Record<string, 'p0'> = {
  paddingBottom: 'p0',
  paddingLeft: 'p0',
  paddingRight: 'p0',
  paddingTop: 'p0',
};

export const glomoRequestRowCells: TableRowCells<ColumnId> = {
  AdminNotification: {
    render: (glomoRequest) => (
      <Td h={1} key="notification" padding="p0" w={40}>
        <GlomoRequestTableLink id={glomoRequest.id}>
          <Box.div display="flex" justifyContent="center" w="100%">
            {shouldShowNotification({
              userType: 'admin',
              status: glomoRequest.status,
              date: glomoRequest.request_approved_at
                ? `${glomoRequest.request_approved_at.replace(/T.*$/, '')}T12:00:00Z`
                : glomoRequest.created_at,
            }) ? (
              <Box.span
                backgroundColor="statusDotBgInfo"
                borderRadius="borderRadiusCircle"
                data-testid="pending-approval-dot"
                h={8}
                w={8}
              />
            ) : null}
          </Box.div>
        </GlomoRequestTableLink>
      </Td>
    ),
  },
  Notification: {
    render: (glomoRequest) => (
      <Td h={1} key="notification" padding="p0" w={40}>
        <GlomoRequestTableLink id={glomoRequest.id}>
          <Box.div display="flex" justifyContent="center" w="100%">
            {shouldShowNotification({ userType: 'hr', status: glomoRequest.status }) ? (
              <Box.span
                backgroundColor="statusDotBgInfo"
                borderRadius="borderRadiusCircle"
                data-testid="pending-approval-dot"
                h={8}
                w={8}
              />
            ) : null}
          </Box.div>
        </GlomoRequestTableLink>
      </Td>
    ),
  },
  Name: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="name" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            <Box.span color="colorTextStrongest" fontWeight="fontWeightBold">
              {glomoRequest.employee_first_name} {glomoRequest.employee_last_name}
            </Box.span>
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  RequestType: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="request-type" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            <Box.span color="colorTextStrongest">
              {humanizeGlomoRequestType[glomoRequest.type]}
            </Box.span>
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  Company: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="company" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            <CompanyCell companyId={glomoRequest.company_id} />
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  TravelStartDate: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="start-date" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            {formatGlomoRequestDate(glomoRequest.trip_start_date)}
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  Status: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="status" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            <GlomoRequestStatusBadge status={glomoRequest.status} />
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  RiskLevel: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="risk-level" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            {glomoRequest.risk_level ? <RiskLevel risk={glomoRequest.risk_level} /> : ''}
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  RequestDate: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="request-date" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            {formatGlomoRequestDate(glomoRequest.created_at)}
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
  ApprovedOrDeniedDate: {
    render: (glomoRequest) => {
      return (
        <Td h={1} key="approved-denied-date" {...paddings}>
          <GlomoRequestTableLink id={glomoRequest.id}>
            {glomoRequest.request_approved_at
              ? formatGlomoRequestDate(glomoRequest.request_approved_at)
              : ''}
          </GlomoRequestTableLink>
        </Td>
      );
    },
  },
};
