import React from 'react';
import { Avatar, Box, Heading, Paragraph, useToast } from '@localyze-pluto/components';
import { useDetailedCaseAdditionalService } from 'modules/additionalServices/api/useDetailedCaseAdditionalService/useDetailedCaseAdditionalService';
import { format } from 'date-fns';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { ServiceDetailsItem } from './ServiceDetailsItem';
import { SectionDivider } from 'components/SectionDivider/SectionDivider';
import { PartnersForm } from './PartnersForm';
import { useCurrentUser } from 'config/CurrentUserContext';

const dataFormatter = (data: string): string => `${format(new Date(data), 'dd. LLLL yyyy')}`;

type ServiceDetailsProps = {
  caseAdditionalServiceId: number;
  companyId?: number;
  destinationCountryId?: number;
};

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  caseAdditionalServiceId,
  companyId,
  destinationCountryId,
}) => {
  const toast = useToast();
  const { isAdmin } = useCurrentUser();
  const {
    data: detailedCaseAdditionalService,
    isSuccess,
    isError,
    error,
  } = useDetailedCaseAdditionalService(caseAdditionalServiceId);

  if (isError) toast(error.message || 'Error fetching additional services.', 'error');

  if (!isSuccess) {
    return (
      <Box.div display="flex" h="50%" justifyContent="center">
        <ContainedLoadingState />
      </Box.div>
    );
  }

  const bookedByFullName = `${detailedCaseAdditionalService.booked_by?.first_name}
  ${detailedCaseAdditionalService.booked_by?.last_name}`;

  return (
    <Box.div
      background="white"
      borderColor="borderSecondary"
      borderRadius="borderRadius30"
      borderStyle="borderStyleSolid"
      borderWidth="borderWidth10"
    >
      <Heading as="h6" fontWeight="fontWeightBold" marginBottom="d0" padding="p4" size="heading60">
        Service details
      </Heading>
      <SectionDivider />

      {detailedCaseAdditionalService.booked_by && (
        <ServiceDetailsItem title="Booked by">
          <Box.span alignItems="center" display="flex" gap="d2" padding="p4">
            <Avatar
              name={bookedByFullName}
              size="small"
              src={detailedCaseAdditionalService.booked_by.avatar_url}
            />
            <Paragraph
              color="colorTextStrongest"
              fontSize="fontSize20"
              fontWeight="fontWeightBold"
              marginBottom="d0"
            >
              {bookedByFullName}
            </Paragraph>
          </Box.span>
        </ServiceDetailsItem>
      )}

      <ServiceDetailsItem title="Booked on">
        <Paragraph color="colorTextStrongest" fontSize="fontSize20" marginBottom="d0" padding="p4">
          {dataFormatter(detailedCaseAdditionalService.created_at)}
        </Paragraph>
      </ServiceDetailsItem>

      {isAdmin ? (
        <ServiceDetailsItem htmlFor="servicePartner" isLabel title="Partner">
          <PartnersForm
            caseAdditionalService={detailedCaseAdditionalService}
            companyId={companyId}
            destinationCountryId={destinationCountryId}
          />
        </ServiceDetailsItem>
      ) : (
        <ServiceDetailsItem title="Partner">
          <Box.span alignItems="center" display="flex" gap="d2" padding="p4">
            {detailedCaseAdditionalService.partner ? (
              <>
                <Avatar
                  name={detailedCaseAdditionalService.partner.name}
                  size="small"
                  src={detailedCaseAdditionalService.partner.logo_url}
                />
                <Paragraph
                  color="colorTextStrongest"
                  fontSize="fontSize20"
                  fontWeight="fontWeightBold"
                  marginBottom="d0"
                >
                  {detailedCaseAdditionalService.partner.name}
                </Paragraph>
              </>
            ) : (
              <Paragraph
                color="colorBackgroundStrongest"
                fontSize="fontSize20"
                lineHeight="lineHeight30"
                marginBottom="d0"
              >
                No partner selected yet
              </Paragraph>
            )}
          </Box.span>
        </ServiceDetailsItem>
      )}

      <ServiceDetailsItem isLast title="Service description">
        <Box.span alignItems="center" display="flex" gap="d2" padding="p4">
          <Paragraph color="colorTextStrongest" fontSize="fontSize20" marginBottom="d0">
            {detailedCaseAdditionalService.additional_service.description || ''}
          </Paragraph>
        </Box.span>
      </ServiceDetailsItem>
    </Box.div>
  );
};
