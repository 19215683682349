import { PageLayout } from 'modules/layout/components/PageLayout';
import React from 'react';
import { Box, Button } from '@localyze-pluto/components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { usePartnersWithConfiguration } from '../hooks/usePartnersWithConfiguration';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { PartnerPreferencesHeader } from './components/PartnerPreferencesHeader/PartnerPreferencesHeader';
import { PartnerInformation } from './components/PartnerInformation';
import { PartnerServices } from './components/PartnerServices/PartnerServices';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useQueryParams } from 'modules/filters/hooks/useQueryParams';

export const PartnerPreferences = (): React.JSX.Element => {
  const {
    queryParams: { service_category_id, company_id },
  } = useQueryParams();

  const { partnerId } = useParams<{
    partnerId: string;
  }>();

  const location = useLocation<{
    from: {
      pathname: string;
      search: string;
    };
  }>();

  const routes = useRoutes<false>();

  const history = useHistory();

  const { partnersWithConfiguration, isSuccess: isSuccessPartnersWithConfiguration } =
    usePartnersWithConfiguration({
      serviceCategoryId: Number(service_category_id),
      companyId: Number(company_id),
      enabled: !!service_category_id && !!company_id,
    });

  const partnerWithConfiguration = partnersWithConfiguration.find(
    (partner) => partner.id === Number(partnerId),
  );

  const preferredConfigurations = partnersWithConfiguration.filter(
    (partner) => partner.isPreferred && partner.id !== partnerWithConfiguration?.id,
  );

  // The "state" can be undefined if the user navigates directly to the page
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const from = location.state?.from || {
    pathname: routes.partners.get(String(company_id)),
    search: '',
  };

  return (
    <PageLayout
      flexed
      maxW="1200px"
      mx="auto"
      px="d16"
      titleButton={
        <Button
          leadingIcon="arrow-left"
          onClick={() => history.push({ pathname: from.pathname, search: from.search })}
          p="d0"
          variant="ghost"
        >
          Back to partners overview
        </Button>
      }
      w="100%"
    >
      {isSuccessPartnersWithConfiguration && partnerWithConfiguration ? (
        <Box.div display="flex" flexDirection="column" gap="d3" pb="d8">
          <PartnerPreferencesHeader
            companyId={Number(company_id)}
            partnerWithConfiguration={partnerWithConfiguration}
            preferredConfigurations={preferredConfigurations}
            serviceCategoryId={Number(service_category_id)}
          />
          <PartnerInformation partnerWithConfiguration={partnerWithConfiguration} />
          <PartnerServices
            partner={partnerWithConfiguration}
            serviceCategoryId={Number(service_category_id)}
          />
        </Box.div>
      ) : (
        <ContainedLoadingState />
      )}
    </PageLayout>
  );
};
