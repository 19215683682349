import React from 'react';
import { Tr, Th, THead } from '@localyze-pluto/components';

export const AdminCompaniesTableHeader = (): React.JSX.Element => {
  const headerColumns = [
    {
      header: 'Company name',
      id: 'company-name',
    },
    {
      header: 'Status',
      id: 'company-status',
    },
    {
      header: '',
      id: 'link-actions',
    },
  ];

  return (
    <THead isSticky style={{ zIndex: '15' }}>
      <Tr>
        {headerColumns.map(({ id, header }) => (
          <Th key={id}>{header}</Th>
        ))}
      </Tr>
    </THead>
  );
};
