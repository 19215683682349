import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import React from 'react';

type FeatureFlagProviderProps = {
  children: React.ReactElement;
};

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagProviderProps): React.ReactElement => {
  return (
    <SplitFactoryProvider
      config={{
        core: {
          authorizationKey: process.env.REACT_APP_SPLIT_IO_API_KEY || '',
          key: 'default',
        },
      }}
    >
      {children}
    </SplitFactoryProvider>
  );
};
