import { isNavComponent, isNavGroup, isNavLink } from './helpers';
import { Link } from './Link';
import { NAVBAR } from './constants';
import { NavGroup } from './NavGroup';
import { NavEntry } from 'modules/layout/components/Navbar/types/NavEntry';
import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useNavData } from './useNavData';
import { InvoicesNavItem } from './InvoicesNavItem';
import { Counters, usePendingCounters } from 'modules/home/api/usePendingCounters';

const renderLinks = (navItems: NavEntry[], counters?: Counters) =>
  navItems.map((navItem) => {
    if (isNavGroup(navItem)) {
      return (
        <NavGroup {...navItem} key={navItem.label}>
          {renderLinks(navItem.items)}
        </NavGroup>
      );
    }

    if (isNavLink(navItem)) {
      const counterValue = navItem.counter ? counters?.[navItem.counter] : undefined;

      return (
        <li id={kebabCase(`sidebar-${navItem.label}`)} key={navItem.to}>
          <Link
            {...navItem}
            counterValue={counterValue}
            onClick={() => {
              trackEvent('sidebar: clicks navigation link', {
                navigatingTo: navItem.to,
                navigatingFrom: window.location.pathname,
              });
            }}
            to={navItem.route || navItem.to}
          />
        </li>
      );
    }

    if (isNavComponent(navItem)) {
      return <InvoicesNavItem key={NAVBAR.INVOICES} />;
    }

    return null;
  });

export const Navbar = (): React.JSX.Element => {
  const allowedNavItems: NavEntry[] = useNavData();
  const { data: counters, isFetched } = usePendingCounters({});

  return (
    <nav
      aria-label="Sidebar"
      className="tw-py-5 tw-flex-1 tw-space-y-1 tw-overflow-hidden tw-flex tw-flex-col"
    >
      <ul
        className={`tw-h-full tw-list-none tw-m-0 tw-p-0 
        tw-overflow-x-hidden tw-overflow-y-auto tw-overscroll-contain
      `}
      >
        {isFetched ? renderLinks(allowedNavItems, counters) : renderLinks(allowedNavItems)}
      </ul>
    </nav>
  );
};
