import React, { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeading,
  DrawerStore,
  Heading,
  Select,
} from '@localyze-pluto/components';
import { Filter } from 'modules/glomoRequests/components/GlomoRequestFilters/Filter';
import { GlomoRequestFilterCommonKey } from 'modules/glomoRequests/constants';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import { GlomoRequestType } from 'modules/glomoRequests/api/types/GlomoRequestType';

export type FiltersData = Partial<{
  risk_level: GlomoRequestRisk;
  status: GlomoRequestStatus;
  type: GlomoRequestType;
  company_id: string;
}>;

export const FiltersDrawer = ({
  store,
  onApply,
  filters,
  initialFilterValues,
}: {
  store: DrawerStore;
  filters: Filter<GlomoRequestFilterCommonKey | 'company_id'>[];
  onApply: (filtersData: FiltersData) => void;
  initialFilterValues: Partial<FiltersData>;
}): React.JSX.Element => {
  const [filtersData, setFiltersData] = useState<FiltersData>(initialFilterValues);

  return (
    <Drawer store={store}>
      <DrawerHeader>
        <DrawerHeading>Filter</DrawerHeading>
      </DrawerHeader>
      <DrawerBody padding="p6">
        <Box.div display="grid" h="100%" w={352}>
          <Box.div display="grid" gap="d4" gridAutoRows="min-content">
            {filters.map((filter) => (
              <Box.div key={filter.id} minWidth={185}>
                <Heading
                  as="h3"
                  color="gray900"
                  fontSize="fontSize20"
                  fontWeight="fontWeightMedium"
                  lineHeight="lh8"
                  marginBottom="d0"
                >
                  {filter.placeholder}
                </Heading>
                <Select
                  aria-label={filter.ariaLabel}
                  id={filter.id}
                  items={filter.items}
                  name={filter.key}
                  placeholder="Select filter"
                  setValue={(value) => setFiltersData({ ...filtersData, [`${filter.key}`]: value })}
                  value={filtersData[filter.key] || ''}
                />
              </Box.div>
            ))}
          </Box.div>
          <Box.div alignSelf="end" display="grid" gap="d3" gridTemplateColumns="1fr 1fr">
            <Button
              onClick={() => {
                setFiltersData({});
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button onClick={() => onApply(filtersData)} variant="primary">
              Apply
            </Button>
          </Box.div>
        </Box.div>
      </DrawerBody>
    </Drawer>
  );
};
