import React, { useState } from 'react';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { Box, Button, Heading, Select } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { Office } from '../Offices/Office';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useOfficesWithSelectOptions } from 'modules/offices/api/useOfficesWithSelectOptions/useOfficesWithSelectOptions';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useHistory, useParams } from 'react-router-dom';

export const ManageOffices = (): React.JSX.Element => {
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>();
  const { isHrAdmin, isAdmin, company_id } = useCurrentUser();
  const routes = useRoutes();
  const history = useHistory();

  const { companyId: companyIdFromUrl } = useParams<{ companyId: string }>();
  const companyId = Number(companyIdFromUrl) || company_id;

  const showAddButton = isAdmin || isHrAdmin;

  const {
    data: offices,
    isPending,
    isSuccess,
  } = useOfficesWithSelectOptions({
    params: { companyId },
  });

  return (
    <SectionCard>
      <SectionCard.Header py="d4">
        <Box.div display="flex" flexDirection="row" justifyContent="space-between">
          <Box.div display="flex" flexDirection="column">
            <Heading as="h2" fontSize="fontSize40" marginBottom="d0">
              Offices
            </Heading>
            <Box.div alignItems="center" marginBottom="d4">
              {isSuccess && (
                <Select
                  aria-label={'office'}
                  items={offices}
                  name="office"
                  setValue={(value) => setSelectedOfficeId(value.toString())}
                />
              )}
            </Box.div>
          </Box.div>
          <Box.div justifyItems="flex-end">
            {showAddButton && (
              <Button
                leadingIcon="plus"
                onClick={() => history.push(routes.addOffice.get(companyId))}
                variant="secondary"
              >
                Add new office
              </Button>
            )}
          </Box.div>
        </Box.div>
      </SectionCard.Header>
      <SectionCard.Content padding="d0">
        {isPending ? (
          <ContainedLoadingState />
        ) : selectedOfficeId ? (
          <Office officeId={Number(selectedOfficeId)} />
        ) : (
          <Box.div>
            <p>You have no offices yet, please add one</p>
          </Box.div>
        )}
      </SectionCard.Content>
    </SectionCard>
  );
};
