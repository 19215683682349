import { Box, Heading, Paragraph } from '@localyze-pluto/components';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { SectionCard } from 'components/SectionCard/SectionCard';
import map from 'lodash/map';
import join from 'lodash/join';
import { useServiceCategories } from 'modules/additionalServices/api/useServiceCategories/useServiceCategories';
import { Partner } from 'modules/additionalServices/types/Partner';
import React from 'react';
import { usePartnerServices } from 'modules/partners/api/usePartnerServices/usePartnerServices';

export const PartnerServices = ({
  partner,
  serviceCategoryId,
}: {
  partner: Partner;
  serviceCategoryId: number;
}): React.JSX.Element => {
  const { data: partnerServices, isSuccess: isSuccessPartnerServices } = usePartnerServices({
    partnerId: partner.id,
    serviceCategoryId,
  });

  const { data: serviceCategory } = useServiceCategories({
    select: (serviceCategories) =>
      serviceCategories.find((serviceCategory) => serviceCategory.id === serviceCategoryId),
  });

  const items = map(partnerServices, (partnerService) => ({
    key: partnerService.additional_service.name,
    value: partnerService.description,
  }));

  if (!isSuccessPartnerServices || !serviceCategory) {
    return <ContainedLoadingState />;
  }

  return (
    <SectionCard>
      <SectionCard.Header py="d2">
        <Heading as="h2" fontSize="fontSize40" marginBottom="d0">
          {serviceCategory.name} services
        </Heading>
      </SectionCard.Header>
      <SectionCard.Content padding="d0">
        <Box.ul mb="0" p="0" w="100%">
          {partnerServices.map((partnerService, index) => (
            <Box.li
              borderBottomColor="colorBorderWeakest"
              borderBottomStyle="borderStyleSolid"
              borderBottomWidth={index !== items.length - 1 ? 'borderWidth10' : 'borderWidth0'}
              display="flex"
              fontSize="fontSize20"
              gap="d4"
              key={partnerService.id}
              px="d6"
              py="d4"
            >
              <Box.div color="contentPrimary" fontWeight="fontWeightBold" w="250px">
                {partnerService.additional_service.name}
              </Box.div>
              <Box.div display="flex" flexDirection="column" w="100%">
                <Paragraph whiteSpace="pre-wrap">{partnerService.description}</Paragraph>
                <Box.div>
                  <Box.span fontWeight="fontWeightMedium">Supported countries: </Box.span>
                  {join(map(partnerService.countries, 'name'), ', ')}
                </Box.div>
              </Box.div>
            </Box.li>
          ))}
        </Box.ul>
      </SectionCard.Content>
    </SectionCard>
  );
};
