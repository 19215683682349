import { Sizes, Status } from './types';
import { CasePipeline as CasePipelineType } from 'modules/pipelines/types/CasePipeline';
import { CaseStepStatus } from 'modules/pipelines/types/CaseStepStatus';
import { CaseStep } from 'modules/talent/pages/talentProfile/components/CasePipeline/components/CaseStep/CaseStep';
import React, { useRef } from 'react';
import { PipelineTitle } from './components/PipelineTitle/PipelineTitle';
import { Box, BoxProps, Button } from '@localyze-pluto/components';
import { styled } from '@localyze-pluto/theme';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';

export type CasePipelineProps = {
  casePipeline: CasePipelineType;
  handleToggle: () => void;
  isOpen: boolean;
  previewStep: (id: number) => void;
  openStep: (id: number) => void;
  deleteStep: (id: number) => void;
};

const CASE_STATUS_MAPPING: Record<CaseStepStatus, Status> = {
  [CaseStepStatus.Open]: Status.Current,
  [CaseStepStatus.Preview]: Status.Incomplete,
  [CaseStepStatus.Completed]: Status.Complete,
};

const getNumberOfCompletedSteps = (caseSteps: CasePipelineType['case_steps']) => {
  return caseSteps.filter((step) => step.status === CaseStepStatus.Completed).length;
};

const CollapsibleContainer = styled.div<BoxProps>`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  height: ${(props) => `${props.h}px`};
`;

export const CasePipeline = ({
  handleToggle,
  isOpen,
  casePipeline,
  previewStep,
  openStep,
  deleteStep,
}: CasePipelineProps & React.HTMLAttributes<HTMLDivElement>): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const routes = useRoutes();

  const stepsListRef = useRef<HTMLDivElement>(null);

  const { case_steps, pipeline } = casePipeline;
  const completedSteps = getNumberOfCompletedSteps(case_steps);

  const title = `${pipeline.title} (${completedSteps}/${case_steps.length})`;

  const linkToCaseEvaluationSummary = isAdmin && !!casePipeline.case_evaluation_summary_id;
  const { data: kase, isSuccess: isSuccessCase } = useCase({
    caseId: casePipeline.case_id,
    enabled: linkToCaseEvaluationSummary,
  });

  const talentId = kase?.talent_id;
  const caseEvaluationSummaryId = casePipeline.case_evaluation_summary_id;
  const pipelineId = casePipeline.pipeline.id;

  const openCaseEvaluationSummary = () => {
    trackEvent('case evaluation summary: click on preview button', {
      pipelineId,
      talentId,
      caseId: kase?.id,
      summaryId: caseEvaluationSummaryId,
      isCaseActive: kase?.current_state !== 'closed',
    });
    window.open(
      routes.caseEvaluationSummary?.get(kase?.id, Number(caseEvaluationSummaryId), pipelineId),
      '_blank',
    );
  };

  return (
    <Box.div data-testid="pipeline">
      <PipelineTitle
        areStepsOpened={isOpen}
        onClick={handleToggle}
        status={casePipeline.completed ? Status.Complete : Status.Current}
        title={title}
      />
      <CollapsibleContainer
        aria-expanded={isOpen}
        aria-hidden={!isOpen}
        h={isOpen ? stepsListRef.current?.scrollHeight : 0}
      >
        <Box.div ref={stepsListRef}>
          {linkToCaseEvaluationSummary && isSuccessCase && (
            <Box.div paddingLeft="d4" py="d2">
              <Button as="a" onClick={openCaseEvaluationSummary} variant="ghost">
                View case evaluation summary
              </Button>
            </Box.div>
          )}
          <Box.ul
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            h="100%"
            listStyleType="none"
            marginLeft="m6"
            marginTop="m2"
            paddingLeft="p0"
          >
            {casePipeline.case_steps.map((caseStep, index) => {
              return (
                <Box.li aria-label={caseStep.name} key={caseStep.id} w="100%">
                  <CaseStep
                    deleteStep={() => deleteStep(caseStep.id)}
                    divider={index !== casePipeline.case_steps.length - 1}
                    label={caseStep.name}
                    openStep={() => openStep(caseStep.id)}
                    previewStep={() => previewStep(caseStep.id)}
                    size={Sizes.Small}
                    status={CASE_STATUS_MAPPING[caseStep.status]}
                  />
                </Box.li>
              );
            })}
          </Box.ul>
        </Box.div>
      </CollapsibleContainer>
    </Box.div>
  );
};

export { Status };
