import * as FLAGS from 'modules/featureFlags/types/flags';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useCurrentUser } from 'config/CurrentUserContext';

const allFlags = Object.values(FLAGS);

export const useIsFeatureFlagActive = (flagName: FLAGS.Flag): boolean => {
  const { company_id, id, isAdmin } = useCurrentUser();
  const splitId = `User-${id}`;

  const { treatments } = useSplitTreatments({
    names: allFlags,
    updateOnSdkUpdate: true,
    attributes: {
      company: `Company-${company_id}`,
      localyze_admin: isAdmin,
    },
    splitKey: splitId,
  });

  return treatments[flagName].treatment === 'on';
};
