import { Offices } from 'modules/company/pages/Offices/Offices';
import React, { useEffect } from 'react';
import { CompanyInformation } from 'modules/company/components/CompanyInformation/CompanyInformation';
import { SubscriptionPlans } from 'deprecated/pages/viewsNew/ChewpacaLayout/Subscription/components/SubscriptionPlans/SubscriptionPlans';
import { useParams } from 'react-router-dom';
import { Team } from 'modules/company/pages/Team/Team';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { useCurrentUser } from 'config/CurrentUserContext';
import { trackPageView } from 'modules/analytics/analytics';
import { PageLayout } from 'modules/layout/components/PageLayout';

export const Company = (): React.JSX.Element => {
  const { isHrAdmin, isAdmin, company_id } = useCurrentUser();
  const { companyId: companyIdFromQuery } = useParams<{ companyId: string }>();

  const canEdit = isAdmin || isHrAdmin;
  const companyId = isAdmin && companyIdFromQuery ? Number(companyIdFromQuery) : company_id;

  const { data: company } = useCompany({ companyId });

  useEffect(() => {
    trackPageView('Profile');
  }, []);

  return (
    <PageLayout title="Company profile">
      {company ? (
        <CompanyInformation canEdit={canEdit} company={company} />
      ) : (
        <ContainedLoadingState />
      )}
      {isAdmin && (
        <>
          <Offices />
          <Team />
          {company && <SubscriptionPlans company={company} />}
        </>
      )}
    </PageLayout>
  );
};
