import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { PARTNER_CONFIGURATIONS_QUERY_KEY } from '../usePartnerConfigurations/usePartnerConfigurations';
import { AxiosErrorWithMessage } from 'types/errors/AxiosErrorWithMessage';

type DeletePartnerConfigurationProps = {
  partnerConfigurationId: number;
};

const deletePartnerConfiguration = async ({
  partnerConfigurationId,
}: DeletePartnerConfigurationProps): Promise<void> => {
  return axios.delete(`/partner_configurations/${partnerConfigurationId}`);
};

export const useDeletePartnerConfiguration = ({
  onError,
  onSuccess,
}: UseMutationOptions<
  void,
  AxiosErrorWithMessage,
  DeletePartnerConfigurationProps,
  'onError' | 'onSuccess'
>): UseMutationResult<void, AxiosErrorWithMessage, DeletePartnerConfigurationProps> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePartnerConfiguration,
    onSuccess: async (_, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [PARTNER_CONFIGURATIONS_QUERY_KEY],
      });

      onSuccess?.(_, variables, context);
    },
    onError,
  });
};
