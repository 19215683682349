import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ServiceCategory } from '../../types/ServiceCategory';
import axios from 'axios';

const getServiceCategories = async (): Promise<ServiceCategory[]> => {
  const { data } = await axios.get<ServiceCategory[]>('/service_categories');
  return data;
};
export const useServiceCategories = <Result = ServiceCategory[]>({
  select,
}: Pick<
  UseQueryOptions<ServiceCategory[], unknown, Result>,
  'select'
> = {}): UseQueryResult<Result> => {
  return useQuery({
    queryKey: ['service-categories'],
    queryFn: () => getServiceCategories(),
    select,
  });
};
