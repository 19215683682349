import {
  Badge,
  Box,
  Button,
  ControlledCheckbox,
  Heading,
  Paragraph,
  useDrawerStore,
  useToast,
} from '@localyze-pluto/components';
import { AdditionalService } from 'modules/additionalServices/types/AdditionalService';
import { Control } from 'react-hook-form';
import { FAMILY_MEMBERS_SERVICE_NAME } from 'modules/additionalServices/constants/familyMembersServiceName';
import React, { useState } from 'react';
import startCase from 'lodash/startCase';
import { isAdditionalServiceCategoryRecommended } from 'modules/additionalServices/utils/isAdditionalServiceCategoryRecommended';
import { CaseType } from 'modules/cases/types/CaseTypeEnum';
import { CaseAdditionalService } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { useServiceCategories } from 'modules/additionalServices/api/useServiceCategories/useServiceCategories';
import { ServiceCategory } from 'modules/additionalServices/types/ServiceCategory';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { groupBy } from 'lodash';
import { AdditionalServiceDrawer } from 'modules/cases/pages/createCase/components/Steps/Step4/AdditionalServiceDrawer/AdditionalServiceDrawer';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { PARTNERS_HUB } from 'modules/featureFlags/types/flags';
import { useStoreState } from '@ariakit/react';

type AdditionalServiceOptionsProps = {
  availableAdditionalServices: AdditionalService[];
  caseAdditionalServices: CaseAdditionalService[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  caseTypeName?: CaseType;
  companyId?: number;
  destinationCountryId?: number;
};

/**
 * Displays a list of additional service checkboxes
 * grouped by service category to be used inside a React Hook Form
 * @param props.additionalServices - The list of additional services to display
 * @param props.control - The React Hook Form control
 */
export const AdditionalServiceOptions = ({
  availableAdditionalServices,
  caseAdditionalServices,
  control,
  caseTypeName,
  companyId,
  destinationCountryId,
}: AdditionalServiceOptionsProps): React.JSX.Element => {
  const isPartnersHubActive = useIsFeatureFlagActive(PARTNERS_HUB);

  const toast = useToast();

  const drawerState = useDrawerStore();
  const isDrawerOpen = useStoreState(drawerState, 'open');
  const [drawerAdditionalService, setDrawerAdditionalService] = useState<AdditionalService>();

  const { data: serviceCategories, isSuccess, isError, error } = useServiceCategories();

  if (isError) toast(error.message || 'Error fetching service categories.', 'error');
  if (!isSuccess) {
    return (
      <Box.div display="flex" h="50%" justifyContent="center">
        <ContainedLoadingState />
      </Box.div>
    );
  }

  const allAdditionalServices = uniqBy(
    [...availableAdditionalServices, ...map(caseAdditionalServices, 'additional_service')],
    'id',
  );
  // We don't want to display the family support option since it's shown in a separate screen
  const additionalServicesWithoutFamily = allAdditionalServices.filter(
    (additionalService) => additionalService.name !== FAMILY_MEMBERS_SERVICE_NAME,
  );

  const groupedServices = groupBy(additionalServicesWithoutFamily, 'service_category.id');

  return (
    <Box.div columnGap="d12" display="grid" rowGap="d10">
      {serviceCategories.map((serviceCategory: ServiceCategory) => {
        const services = groupedServices[serviceCategory.id];
        // There's a chance that services could be undefined but TS is not catching that
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!services || services.length === 0) {
          return null;
        }
        return (
          <Box.div display="flex" flexDirection="column" gap="d4" key={serviceCategory.id}>
            <Box.div alignItems="center" display="flex" gap="d4">
              <Heading as="h3" color="contentPrimary" marginBottom="m0" size="heading60">
                {startCase(serviceCategory.name)}
              </Heading>
              {isAdditionalServiceCategoryRecommended(serviceCategory, caseTypeName) && (
                <Badge size="small">Recommended</Badge>
              )}
            </Box.div>
            <Box.div columnGap="d12" display="grid" rowGap="d3">
              {services.map((service) => {
                const checkboxId = `additionalServices.${service.id}`;

                return (
                  <ControlledCheckbox
                    control={control}
                    disabled={caseAdditionalServices.some(
                      (caseAdditionalService) =>
                        caseAdditionalService.additional_service.id === service.id &&
                        !!caseAdditionalService.marketplace_order_id,
                    )}
                    id={checkboxId}
                    key={service.id}
                    name={checkboxId}
                  >
                    <Paragraph color="colorTextStronger" fontSize="fontSize20" marginBottom="d2">
                      {service.name}
                    </Paragraph>
                    <Paragraph color="colorTextStronger" fontSize="fontSize20" marginBottom="d0">
                      {service.description || ''}
                      {isPartnersHubActive && (
                        <Button
                          display="inline-block"
                          onClick={() => {
                            setDrawerAdditionalService(service);
                            drawerState.show();
                          }}
                          paddingLeft="d1"
                          paddingRight="d0"
                          py="d0"
                          type="button"
                          variant="ghost"
                        >
                          Learn more
                        </Button>
                      )}
                    </Paragraph>
                  </ControlledCheckbox>
                );
              })}
            </Box.div>
          </Box.div>
        );
      })}
      {isDrawerOpen && drawerAdditionalService && (
        <AdditionalServiceDrawer
          additionalService={drawerAdditionalService}
          companyId={companyId}
          destinationCountryId={destinationCountryId}
          store={drawerState}
        />
      )}
    </Box.div>
  );
};
