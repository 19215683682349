import { Button, Icon, useModalStore, useToast, Tr, Td } from '@localyze-pluto/components';

import CompanyStateDropDown from 'deprecated/components/DropDown/CompanyStateDropDown';
import { Link } from 'react-router-dom';
import React from 'react';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { updateCompany } from 'deprecated/utils/helper/admin/companies';
import { InvoicesModal } from 'modules/invoices/components/InvoicesModal';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { useStoreState } from '@ariakit/react';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';

export const CompanyCard = ({
  updateCompanies,
  company,
  companyState,
  companyStateOptions,
  paginationParams,
}) => {
  const toast = useToast();
  const invoiceModalState = useModalStore();
  const isModalInvoicesOpen = useStoreState(invoiceModalState, 'open');
  const routes = useRoutes();
  const isPartnersHubActive = useIsFeatureFlagActive('partners_hub');

  const selectCompanyState = (companyState) => {
    const params = { company: { state: companyState } };
    updateCompany(
      company.id,
      params,
      () => {
        toast(`Company state updated successfully.`, 'success');
        updateCompanies(company.company_name, companyState);
      },
      (error) => console.error(error),
    );
  };

  return (
    <Tr key={company.name}>
      <Td>{company.name}</Td>
      <Td>
        <CompanyStateDropDown
          onChangeStatus={selectCompanyState}
          value={companyState}
          companyStateOptions={companyStateOptions}
        />
      </Td>
      <Td>
        <Link
          aria-label="Navigate to company's report"
          style={{
            marginRight: 8,
          }}
          to={{
            pathname: routes.reportingPage.get(company.id),
            state: {
              backTo: 'Companies',
              paginationParams: paginationParams,
              prevPath: window.location.pathname,
            },
          }}
          onClick={() => {
            trackEvent("companies: navigate to company's report");
          }}
        >
          <Icon
            color={{
              _: 'colorIconWeak',
              hover: 'colorIconInfo',
            }}
            decorative
            icon="file-bar-chart"
          />
        </Link>
        <Link
          aria-label="Navigate to company's profile"
          style={{
            marginRight: 8,
          }}
          to={{
            pathname: isPartnersHubActive
              ? routes.settings.get(company.id)
              : routes.companyProfile.get(company.id),
            state: { backTo: 'Companies', paginationParams, prevPath: window.location.pathname },
          }}
        >
          <Icon
            color={{
              _: 'colorIconWeak',
              hover: 'colorIconInfo',
            }}
            decorative
            icon="cog"
          />
        </Link>
        {!isPartnersHubActive && (
          <>
            <Button variant="ghost" onClick={() => invoiceModalState.show()}>
              <Icon
                color={{
                  _: 'colorIconWeak',
                  hover: 'colorIconInfo',
                }}
                icon="credit-card"
                title="Invoices"
              />
            </Button>
            {isModalInvoicesOpen && (
              <InvoicesModal modalState={invoiceModalState} companyId={company.id} />
            )}
          </>
        )}
      </Td>
    </Tr>
  );
};
