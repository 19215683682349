import React, { useState } from 'react';

import { Company } from 'modules/company/types/Company';
import { useCompanyFields } from 'modules/company/components/CompanyInformation/utils/useCompanyFields';
import { useUpdateCompany } from 'modules/company/api/useUpdateCompany/useUpdateCompany';
import { Box, Button, Heading, useModalStore, useToast } from '@localyze-pluto/components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledFormField } from 'components/ControlledFormField/ControlledFormField';
import * as yup from 'yup';
import { useCurrentUser } from 'config/CurrentUserContext';
import { EditButton } from 'modules/company/components/EditButton/EditButton';
import { InvoicesModal } from 'modules/invoices/components/InvoicesModal';

type Props = {
  company: Company;
  canEdit: boolean;
};

export type BillingInformationValues = {
  id: number;
  registered_name: string;
  billing_center_id: string;
  billing_invoice_email: string;
  billing_street_and_number: string;
  billing_postal_code: string;
  billing_city: string;
  billing_country_id: string;
  vat_number: string;
};

const schema = yup.object({
  id: yup.number().required(),
  registered_name: yup.string().required(),
  billing_center_id: yup.string().required('Please select a billing center.'),
  billing_street_and_number: yup.string().required(),
  billing_postal_code: yup.string().required(),
  billing_city: yup.string().required(),
  billing_country_id: yup.string().required(),
  billing_invoice_email: yup.string().required(),
  vat_number: yup.string().required(),
});

export const BillingInformation = ({ company, canEdit }: Props): React.JSX.Element => {
  const toast = useToast();

  const invoiceModalState = useModalStore();
  const { isAdmin } = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: updateCompany } = useUpdateCompany({
    onSuccess: () => {
      setIsEditing(false);
      toast('The billing information was successfully updated.', 'success');
    },
    onError: (error) => {
      toast(
        error.response?.data.message ||
          'An error has occurred while updating the company information.',
        'error',
      );
    },
  });

  const onSubmit = async (values: BillingInformationValues) => {
    updateCompany(values);
  };

  const { billingFields } = useCompanyFields(isAdmin);

  const { control, handleSubmit, reset } = useForm({
    values: {
      ...company,
      billing_country_id: company.billing_country_id?.toString() || '',
      billing_center_id: company.billing_center.id.toString(),
      billing_invoice_email: company.billing_invoice_email || '',
      vat_number: company.vat_number || '',
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  return (
    <Box.section backgroundColor="bgPrimary" borderRadius="borderRadius40">
      <Box.div
        alignItems="center"
        borderBottomColor="colorBorderWeaker"
        borderBottomStyle="borderStyleSolid"
        borderBottomWidth="borderWidth10"
        display="flex"
        justifyContent="space-between"
        padding="d6"
      >
        <Heading as="h2" marginBottom="d0" size="title-subsection">
          Billing information
        </Heading>
        <Button
          onClick={invoiceModalState.toggle}
          trailingIcon="square-arrow-out-up-right"
          variant="secondary"
        >
          View invoices
        </Button>
      </Box.div>
      <Box.form mx="auto" onSubmit={handleSubmit(onSubmit)} padding="d6">
        <Box.div alignItems="end" display="flex" flexDirection="column">
          {canEdit && (
            <EditButton
              isEditing={isEditing}
              reset={reset}
              setIsEditing={setIsEditing}
              variant="ghost"
            />
          )}
        </Box.div>
        <Box.div display="grid" gap="d4" gridTemplateColumns={{ lg: 3 }}>
          {billingFields.map((field) => {
            return (
              <ControlledFormField
                control={control}
                field={field}
                isEditing={isEditing}
                key={field.id}
              />
            );
          })}
        </Box.div>
      </Box.form>
      <InvoicesModal companyId={company.id} modalState={invoiceModalState} />
    </Box.section>
  );
};
