import { Box, Button, Select } from '@localyze-pluto/components';
import { PageLayout } from 'modules/layout/components/PageLayout';
import React, { useState } from 'react';
import { Office } from './Office';
import { useOfficesWithSelectOptions } from 'modules/offices/api/useOfficesWithSelectOptions/useOfficesWithSelectOptions';
import { useHistory, useParams } from 'react-router-dom';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCurrentUser } from 'config/CurrentUserContext';

export const Offices = (): React.JSX.Element => {
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>();
  const { isHrAdmin, isAdmin, company_id } = useCurrentUser();
  const routes = useRoutes();
  const history = useHistory();

  const { companyId: companyIdFromUrl } = useParams<{ companyId: string }>();
  const companyId = Number(companyIdFromUrl) || company_id;

  const showAddButton = isAdmin || isHrAdmin;

  const {
    data: offices,
    isPending,
    isSuccess,
  } = useOfficesWithSelectOptions({
    params: { companyId },
  });

  return (
    <PageLayout flexed title="Offices">
      <Box.div alignItems="center" display="flex" justifyContent="space-between" marginBottom="m4">
        <Box.div>
          {isSuccess && (
            <Select
              aria-label={'office'}
              items={offices}
              name="office"
              setValue={(value) => setSelectedOfficeId(value.toString())}
            />
          )}
        </Box.div>

        <Box.div display="flex" gap="d2">
          {showAddButton && (
            <Button
              leadingIcon="plus"
              onClick={() => history.push(routes.addOffice.get(companyId))}
              variant="secondary"
            >
              Add office
            </Button>
          )}
        </Box.div>
      </Box.div>
      {isPending ? (
        <ContainedLoadingState />
      ) : selectedOfficeId ? (
        <Office officeId={Number(selectedOfficeId)} />
      ) : (
        <Box.div>
          <p>You have no offices yet, please add one</p>
        </Box.div>
      )}
    </PageLayout>
  );
};
