import { Box, Callout, Paragraph } from '@localyze-pluto/components';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';

import React from 'react';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

export const AdminCallout = ({
  glomoRequest,
}: {
  glomoRequest: GlomoRequest;
}): React.JSX.Element => {
  return (
    <>
      {glomoRequest.status === 'pending_assessment_approval' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="m0">
            HR has not submitted this request for risk assessment yet.
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'risk_assessment_in_progress' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="m0">
            HR has submitted this request for risk assessment on{' '}
            <Box.strong>{formatDate(glomoRequest.assessment_approved_at)}</Box.strong>. Please add
            the risk assessment results here once rhome has completed the evaluation of the trip.
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'risk_assessment_in_review' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="m0">
            HR is currently reviewing the risk assessment results to decide if they will approve or
            deny the trip.
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'risk_assessment_denied' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="m0">
            HR has denied the risk assessment request.
          </Paragraph>
        </Callout>
      )}
    </>
  );
};
