import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  CompanyFormValues,
  UseUpdateCompanyProps,
  UseUpdateCompanyResult,
} from './useUpdateCompany.types';
import { queryClient } from 'config/queryClient';
import { ADMIN_COMPANIES_QUERY_KEY } from '../useAdminCompanies/useAdminCompanies';
import { Company } from 'modules/company/types/Company';
import { COMPANY_QUERY_KEY } from '../useCompany/useCompany';

const updateCompany = async (
  company: Partial<CompanyFormValues> & { id: number },
): Promise<Company> => {
  const { data } = await axios.put<Company>(`/companies/${company.id}`, company);

  return data;
};

/**
 * Updates a company's information
 * @param props.onSuccess - callback to call on success of request
 * @param props.onError - callback to call on error of request
 */
export const useUpdateCompany = ({
  onSuccess,
  onError,
}: UseUpdateCompanyProps): UseUpdateCompanyResult => {
  return useMutation({
    mutationFn: updateCompany,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [ADMIN_COMPANIES_QUERY_KEY] });
      queryClient.setQueryData([COMPANY_QUERY_KEY, variables.id], data);

      onSuccess?.(data, variables, context);
    },
    onError,
  });
};
