import { getCaseOverviewsParams } from 'modules/caseOverview/utils/getCaseOverviewsParams';
import { Route } from 'types/Route';

export type CommonRoute =
  | 'addOffice'
  | 'case'
  | 'cases'
  | 'companyBilling'
  | 'companyProfile'
  | 'createCase'
  | 'glomoRequest'
  | 'glomoRequestsList'
  | 'home'
  | 'knowledge'
  | 'manageCompany'
  | 'reportingPage'
  | 'settings'
  | 'talentProfile'
  | 'talents'
  | 'tasks'
  | 'team'
  | 'userProfile';

/**
 * Routes shared by Admin & HR (non-Talent) users
 */
export const COMMON_ROUTES: Record<CommonRoute, Route> = {
  home: {
    url: '/',
    get: () => '/',
  },
  userProfile: {
    url: '/user-profile',
    get: () => '/user-profile',
  },
  knowledge: {
    url: '/knowledge',
    get: () => '/knowledge',
  },
  tasks: {
    url: '/tasks',
    get: () => '/tasks',
  },
  createCase: {
    url: '/create-case',
    get: () => '/create-case',
  },
  glomoRequestsList: {
    url: '/requests',
    get: () => '/requests',
  },
  glomoRequest: {
    url: '/requests/:id',
    get: (id) => `/requests/${id}`,
  },
  case: {
    url: '/cases/:id',
    get: (id) => `/cases/${id}`,
  },
  cases: {
    url: '/cases',
    get: (isAdmin, isViewer, userId) =>
      `/cases${getCaseOverviewsParams(!!isAdmin, !!isViewer, Number(userId))}`,
  },
  talentProfile: {
    url: '/talents/:id',
    get: (id) => `/talents/${id}`,
  },
  talents: {
    url: '/talents',
    get: () => '/talents',
  },
  companyProfile: {
    url: '/companies/:companyId',
    get: (id) => `/companies/${id}`,
  },
  addOffice: {
    url: '/add-office/:companyId',
    get: (companyId) => `/add-office/${companyId}`,
  },
  team: {
    url: '/companies/:companyId/team',
    get: (companyId) => `/companies/${companyId}/team`,
  },
  reportingPage: {
    url: '/reporting/:id',
    get: (id) => `/reporting/${id}`,
  },
  settings: {
    url: '/companies/:companyId/settings',
    get: (companyId) => `/companies/${companyId}/settings`,
  },
  companyBilling: {
    url: '/companies/:companyId/billing',
    get: (companyId) => `/companies/${companyId}/billing`,
  },
  manageCompany: {
    url: '/companies/:companyId/profile',
    get: (companyId) => `/companies/${companyId}/profile`,
  },
};
