import { PageLayout } from 'modules/layout/components/PageLayout';
import React, { useEffect } from 'react';
import { CaseDetailsHeader } from '../components/CaseDetailsHeader';
import { useHistory, useParams } from 'react-router-dom';
import { useCase } from 'modules/cases/api/useCase/useCase';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useCaseType } from 'modules/cases/api/useCaseType/useCaseType';
import { Box, Button } from '@localyze-pluto/components';
import { Documents } from '../components/Documents/Documents';
import { CaseAdditionalServices } from 'modules/additionalServices/components/CaseAdditionalServices/CaseAdditionalServices';
import { CaseActivityCard } from '../components/CaseActivityCard/CaseActivityCard';
import { CaseDetailsCard } from '../components/CaseDetailsCard/CaseDetailsCard';
import { CaseManagement } from 'modules/caseDetails/components/CaseManagement/CaseManagement';
import { isForbiddenError } from 'modules/utils/isForbiddenError';
import { AxiosError } from 'axios';
import { CaseForbiddenError } from 'modules/caseDetails/components/CaseForbiddenError';
import { useTalentQuery } from 'modules/talent/api/useTalentQuery/useTalentQuery';
import { PersonalDataCollectionConsentCallout } from 'modules/talent/pages/talentProfile/components/PersonalDataCollectionConsentCallout';
import { shouldShowConsentCallout } from 'modules/talent/pages/talentProfile/utils/shouldShowConsentCallout';

export const CaseDetailsPage = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const caseId = Number(id);
  const {
    data: talentCase,
    isSuccess: isSuccessCase,
    isError,
    error,
  } = useCase({
    caseId,
  });
  const { data: caseType, isSuccess: isSuccessCaseType } = useCaseType(
    talentCase?.case_type_id ?? 0,
    !!talentCase,
  );

  const { data: talent, isSuccess: isSuccessTalent } = useTalentQuery(
    Number(talentCase?.talent_id),
  );

  useEffect(() => {
    if (talent) {
      document.title = `${talent.first_name} ${talent.last_name}`;
    }

    return () => {
      document.title = 'Localyze';
    };
  }, [talent]);

  if (isError && isForbiddenError(error as AxiosError)) {
    return <CaseForbiddenError />;
  }

  if (!isSuccessTalent || !isSuccessCase || !isSuccessCaseType) {
    return <ContainedLoadingState />;
  }

  return (
    <PageLayout
      flexed
      maxW={1094}
      mx="auto"
      px="d8"
      titleButton={
        <Button leadingIcon="arrow-left" onClick={goBack} variant="ghost">
          Back
        </Button>
      }
      w="100%"
    >
      <Box.div
        display="flex"
        flexDirection="column"
        gap="d4"
        justifyContent="center"
        marginBottom="m4"
        w="100%"
      >
        <CaseDetailsHeader caseType={caseType} talentCase={talentCase} />
        {shouldShowConsentCallout(talent) && <PersonalDataCollectionConsentCallout />}
        <CaseActivityCard />
        <Box.div
          columnGap="d4"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr"
          maxH="600px"
          minH="400px"
        >
          <CaseManagement caseId={caseId} />
          <CaseAdditionalServices
            caseId={caseId}
            companyId={talent.company_id}
            destinationCountryId={talentCase.destination_country_id}
          />
        </Box.div>
        <Documents caseId={caseId} />
        <CaseDetailsCard />
      </Box.div>
    </PageLayout>
  );
};
