import { Badge, Box, Button, Heading } from '@localyze-pluto/components';
import { useCurrentUser } from 'config/CurrentUserContext';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { useRoutes } from 'modules/routes/useRoutes/useRoutes';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ListItem = ({
  hasBorderBottom = true,
  to,
  text,
  badge,
}: {
  hasBorderBottom?: boolean;
  to: string;
  text: string;
  badge?: React.JSX.Element;
}) => {
  return (
    <Box.li
      borderBottomColor={hasBorderBottom ? 'borderSecondary' : undefined}
      borderBottomStyle={hasBorderBottom ? 'borderStyleSolid' : undefined}
      borderBottomWidth={hasBorderBottom ? 'borderWidth10' : undefined}
    >
      <Button
        as={Link}
        color="contentPrimary"
        fontWeight="fontWeightBold"
        fullWidth
        justifyContent="space-between"
        lineHeight="lh6"
        p="d6"
        to={to}
        trailingIcon="chevron-right"
        variant="ghost"
      >
        <Box.span display="flex" gap="d4">
          {text} {badge}
        </Box.span>
      </Button>
    </Box.li>
  );
};

export const Settings = (): React.JSX.Element => {
  const { isAdmin } = useCurrentUser();
  const params = useParams<{ companyId: string }>();

  const { data: company } = useCompany({
    companyId: Number(params.companyId),
    enabled: isAdmin && !!params.companyId,
  });

  const pageTitle = isAdmin && company ? `Settings - ${company.name}` : 'Settings';

  const routes = useRoutes();

  return (
    <PageLayout
      flexed
      maxW={1094}
      mx="auto"
      px="d8"
      title={isAdmin ? undefined : pageTitle}
      titleButton={
        isAdmin ? (
          <Button
            as={Link}
            leadingIcon="arrow-left"
            p="d0"
            to={routes.companies?.url}
            variant="ghost"
          >
            Back to Companies
          </Button>
        ) : undefined
      }
      w="100%"
    >
      {isAdmin && (
        <Box.div>
          <Heading size="title-screen">{pageTitle}</Heading>
        </Box.div>
      )}
      <Box.ul
        backgroundColor="bgPrimary"
        borderColor="borderSecondary"
        borderRadius="borderRadius40"
        borderStyle="borderStyleSolid"
        borderWidth="borderWidth10"
        display="flex"
        flexDirection="column"
        listStyleType="none"
        mb="d0"
        paddingLeft="d0"
        w="100%"
      >
        <ListItem
          text={isAdmin ? 'Manage company profile' : 'Manage your company profile'}
          to={routes.manageCompany.get(params.companyId)}
        />
        <ListItem
          text={isAdmin ? 'Manage team' : 'Manage your team'}
          to={routes.team.get(params.companyId)}
        />

        {!isAdmin && (
          <ListItem
            text="Manage your integrations"
            to={routes.companyIntegrations?.get(params.companyId) || ''}
          />
        )}
        {!isAdmin && (
          <ListItem
            badge={<Badge>New</Badge>}
            text="Manage your partners"
            to={routes.partners?.get(params.companyId) || ''}
          />
        )}
        <ListItem
          hasBorderBottom={false}
          text="Billing and invoices"
          to={routes.companyBilling.get(params.companyId)}
        />
      </Box.ul>
    </PageLayout>
  );
};
