import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Box } from '@localyze-pluto/components';
import { FieldValues, useForm } from 'react-hook-form';
import { validationSchema } from 'modules/company/pages/AddOffice/constants';
import { ControlledFormField } from 'components/ControlledFormField/ControlledFormField';
import FormSection from 'modules/company/components/FormSection/FormSection';
import { AddOfficePayload } from 'modules/offices/api/useAddOffice/useAddOffice.types';
import { useOfficeFields } from 'modules/company/pages/Offices/useOfficeFields';
import { OfficeWithOfficeInformation } from 'deprecated/api/companies/types/OfficeAPIResponse';
import { UseFormReset } from 'react-hook-form/dist/types/form';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';

export const OfficeForm = ({
  office,
  isEditing,
  onSubmit,
  headerAction,
}: {
  office?: OfficeWithOfficeInformation;
  isEditing: boolean;
  onSubmit: (values: AddOfficePayload) => void;
  headerAction?: <T extends FieldValues>(reset: UseFormReset<T>) => React.JSX.Element;
}): React.JSX.Element => {
  const { getInitialValues, employeeFields, addressFields, taxFields, generalFields } =
    useOfficeFields();

  const { control, handleSubmit, reset } = useForm({
    values: getInitialValues(office),
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });

  const isPartnersHubActive = useIsFeatureFlagActive('partners_hub');

  return (
    <Box.form
      id="officeForm"
      maxWidth={isPartnersHubActive ? '' : '1000px'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormSection headerAction={headerAction && headerAction(reset)} heading="Office information">
        {generalFields.map((field) => {
          return (
            <ControlledFormField
              control={control}
              field={field}
              isEditing={isEditing}
              key={field.id}
            />
          );
        })}
      </FormSection>
      <FormSection heading="Office address">
        {addressFields.map((field) => {
          return (
            <ControlledFormField
              control={control}
              field={field}
              isEditing={isEditing}
              key={field.id}
            />
          );
        })}
      </FormSection>
      <FormSection heading="Employee information">
        {employeeFields.map((field) => {
          return (
            <ControlledFormField
              control={control}
              field={field}
              isEditing={isEditing}
              key={field.id}
            />
          );
        })}
      </FormSection>
      <FormSection border={false} columns={1} heading="Social security & tax information">
        {taxFields.map((field) => {
          return (
            <ControlledFormField
              control={control}
              field={field}
              isEditing={isEditing}
              key={field.id}
            />
          );
        })}
      </FormSection>
    </Box.form>
  );
};
