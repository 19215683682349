import { Filter } from '../components/GlomoRequestFilters/Filter';
import {
  GlomoRequestFilterCommonKey,
  RISK_LEVELS_SELECT_ITEMS,
  GLOMO_REQUEST_TYPE_ITEMS,
  GLOMO_REQUEST_NEW_STATUS_SELECT_ITEMS,
} from '../constants';

export const getGlomoRequestFilters = (
  shouldReturnBusinessTrip: boolean,
): Filter<GlomoRequestFilterCommonKey>[] => {
  return [
    {
      id: 'risk-level-filter',
      key: 'risk_level',
      items: RISK_LEVELS_SELECT_ITEMS,
      placeholder: 'Risk level',
      ariaLabel: 'Select one risk level',
      value: undefined,
    },
    {
      id: 'status-filter',
      key: 'status',
      items: GLOMO_REQUEST_NEW_STATUS_SELECT_ITEMS,
      placeholder: 'Status',
      ariaLabel: 'Select one status',
      value: undefined,
    },
    {
      id: 'request-type-filter',
      key: 'type',
      items: shouldReturnBusinessTrip
        ? GLOMO_REQUEST_TYPE_ITEMS
        : GLOMO_REQUEST_TYPE_ITEMS.slice(0, 2),
      placeholder: 'Request type',
      ariaLabel: 'Select one request type',
      value: undefined,
    },
  ];
};
